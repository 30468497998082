@use "../mixins";
@use "../variables";

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0 auto;
  padding: 30px 40px;
  background-color: variables.$color-cool-dark-gray-day;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

  width: 600px;
  @media (max-width: 650px) {
    width: 90%;
  }
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
  table {
    width: 100%;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.button-modal {
  padding: 10px 20px;
  @include mixins.text-p;
  font-size: 16px;
  background-color: variables.$color-transp-panel;
  text-shadow: variables.$glow-blue;
  transition: background-color variables.$time-transition-medium ease;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
