@use '../variables.scss';
@use "../mixins";

#contact {
  .grid-layout {
    justify-items: normal;

    @include mixins.media(">mobile") {
      grid-template: auto auto / 3fr 2fr;

      .contact-details-card {
        grid-area: 1 / 1 / 2 / 3;
      }
      .route-card {
        grid-area: 2 / 1 / 3 / 2;
      }
      .small-cards {
        grid-area: 2 / 2 / 3 / 3;
      }
    }
  }
}
