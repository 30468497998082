@use "sass:math";
@use '../mixins.scss';

#home {
  .hero-wrapper {
    width: 100%;
    height: MAX(100vh - 100px, 900px);
    display: flex;
    justify-content: center;

    .hero {
      height: min-content;
      width: 100%;
      margin: auto 0;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .title {
        margin-bottom: 30px;
        @include mixins.media("<desktop") {
          width: min-content;
        }
      }

      .image-carousel {
        margin-bottom: 50px;
        height: 200px;
        @include mixins.media("<tablet-portrait") {
          height: 150px;
          img + img {
            margin-left: 10px;
          }
        }
      }

      .intro-panel {
        margin-bottom: 30px;
      }

      .scroll-down-arrow {
        margin-bottom: 40px;
      }

      .background-logo {
        position: absolute;
        z-index: -1;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 53%;
        height: 80vw;
        opacity: .4;
        mix-blend-mode: soft-light;

        @include mixins.media("<desktop") {
          height: 100vw;
        }
        @include mixins.media("<tablet-portrait") {
          height: 120vw;
          top: 45%;
        }
        @include mixins.media("<mobile") {
          height: 130vw;
          top: 32%;
        }
        @include mixins.media("mobile-pointer") {
          opacity: .12;
        }
      }
    }
  }
}
