@use '../variables.scss';

#address-book {
  > div {
    width: 100%;
  }
  .card {
    width: auto;
    max-width: initial;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 25px;
    .contactheader {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 24px;
        display: inline-block;
        @media (min-width: 767px) {
          white-space: nowrap;
        }
        .tiny {
          font-size: 16px;
        }
      }
    }
    p {
      margin-top: 8px;
    }
  }

  > * + * {
    margin-top: 10px;
  }
}
.zwerver-labels {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  &.xs {
    justify-content: flex-start;
    margin-top: 0 !important;
    @media (min-width: 767px) {
      display: none;
    }
  }
  &.lg {
    justify-content: flex-end;
    @media (max-width: 767px) {
      display: none;
    }
  }
  div {
    padding: 3px 3px;
    span {
      display: block;
      padding: 3px;
      border-radius: 3px;
      border: 1px solid #c9b6b6;
      &.fire {
        border: none;
        color: #ffffff;
        text-shadow: 0px -2px 4px #ffbb33, 0px -2px 10px #ff9233, 0px -10px 20px #ff4800, 0px -20px 40px #ff1a1a;
      }
      &.fire-gold {
        border: none;
        color: goldenrod;
        text-shadow: 0px -2px 4px #fff, 0px -2px 10px #FF3, 0px -10px 20px         #F90, 0px -20px 40px #C33;
      }
      &.fire-darkgold {
        border: none;
        color: #0e0b07;
        text-shadow: 0px -2px 4px #d79d5b, 0px -2px 10px #b6803f, 0px -10px 20px         #F90, 0px -20px 40px #C33;
      }
      &.fire-red {
        border: none;
        color: #ff3636;
        text-shadow: 0px -2px 4px #d46f6f, 0px -2px 10px #ff6333, 0px -10px 20px         #F90, 0px -20px 40px #ff0000;
      }
      &.internetcom {
        border: none;
        font-family: "Bitstream Vera Sans Mono", Monaco, "Courier New", Courier, monospace;
        color: green;
      }
      &.bestuur {
        background-color: #d3d02b;
        color: #151f3a;
        padding: 5px 10px;
        border-radius: 10px;
        font-weight: bold;
      }
    }
  }
}

.emergency {
  color: #ff4b4b;
  font-weight: bolder;
}
