@use "sass:map";
@use "variables";

// Text related

@mixin text-p {
  font-family: variables.$font-sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 135%;
}

// Media queries (approach by Dmitry Sheiko: https://codepen.io/dsheiko/pen/KeLGy)

$screen-width-mobile: 600px;
$screen-width-tablet-portrait: 900px;
$screen-width-desktop: 1200px;

@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "fine-pointer": "(pointer: fine)",
    "mobile-pointer": "(pointer: none), (pointer: coarse)",
    ">mobile": "(min-width: #{$screen-width-mobile + 1})",
    "<mobile": "(max-width: #{$screen-width-mobile})",
    ">tablet-portrait": "(min-width: #{$screen-width-tablet-portrait+ 1})",
    "<tablet-portrait": "(max-width: #{$screen-width-tablet-portrait})",
    ">desktop": "(min-width: #{$screen-width-desktop + 1})",
    "<desktop": "(max-width: #{$screen-width-desktop})"
  );
  @return map.get($condMap, $c);
}

@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ( $query != "" ) {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query}  { @content; }
}
