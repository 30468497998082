@use "../mixins";
@use "../variables";

.photoalbum-overview-grid {
  justify-items: normal;

  @include mixins.media(">mobile") {
    grid-template-columns: 1fr 1fr;
  }
  @include mixins.media(">tablet-portrait") {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include mixins.media(">desktop") {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .albumwrapper {
    // keep 1:1 aspect ratio
    padding-bottom: 100%;
    position: relative;

    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;

    .padded {
      padding: 30px 40px;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    h2 {
      font-size: 32px;
      font-weight: 600;
      font-family: variables.$font-sans-serif;
      word-wrap: break-word;
    }

    a {
      text-decoration: none;
      background-color: #0000008c;
    }

    &:hover {
      filter: variables.$glow-blue-hover-filter;
    }
  }
}

.photoalbum-editpage-grid {
  justify-items: normal;

  @include mixins.media(">mobile") {
    grid-template: auto auto / 2fr 2fr;

    .edit {
      grid-area: 1 / 1 / span 1 / span 1;
    }
    .upload {
      grid-area: 1 / 2 / span 1 / span 1;
    }
    .helptext {
      grid-area: 2 / 1 / span 1 / span 2;
    }
    .photos {
      grid-area: 3 / 1 / span 1 / span 2;
    }
    .delete {
      grid-area: 4 / 1 / span 1 / span 2;
    }
  }
}

.photoroll {
  display: flex;
  flex-wrap: wrap;
  margin: 2px;
  width: 100%;

  &::after {
    content: '';
    flex-grow: 999999999;
    order: 999999999;
  }

  > div {
    display: block;
    margin: 2px;
    position: relative;

    > i {
      display: block;
    }
  
    > img {
      position: absolute;
      vertical-align: bottom;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.navbox {
  position: absolute;
  top: 20px;
  left: 20px;
}

.albumtitle {
  @media (max-width: 500px) {
    font-size: 57px;
    line-height: 57px;
  }
}