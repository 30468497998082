@use 'variables.scss';
@use 'mixins.scss';

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600&display=swap);

// Text

h1,h2,h3,h4,p,a,label,button,input,textarea,th,td,li,select,span{
  color: variables.$color-cool-white;
  overflow-wrap: break-word;
}

#dz-app {
  overflow-x: hidden;
}

body {
  overflow-y: overlay;

  &::before {
    // Special way of handling background images to improve scroll performance!
    // https://medium.com/vehikl-news/fixed-background-image-performance-issue-6b7d9e2dbc55
    background-color: variables.$color-black;
    background-image: url(assets/bg_day.webp);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    content: '';
    height: 110%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    will-change: transform;
    z-index: -1;
  }
  &.bg-plain::before {
    background-image: none;
  }
}

main {
  max-width: 960px;
  margin: 100px auto;
  padding: 0 variables.$layout-spacing;
  overflow-x: visible;

  @include mixins.media("<desktop") {
    max-width: 700px;
  }
  @include mixins.media("<tablet-portrait") {
    max-width: 100%;
  }
  @include mixins.media("<mobile") {
    padding: 0 variables.$layout-spacing-mobile;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgba(1, 1, 1, 0.3);
  
  &:vertical {
    background: rgba(1, 1, 1, 0.3);
  }
  &:hover {
    background: rgba(1, 1, 1, 0.45);
  }
  &:active {
    background: rgba(1, 1, 1, 0.6);
  }
}
::-webkit-scrollbar-button,
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  display: none;
}

.spaced-title {
  @include mixins.media(">mobile") {
    margin-top: 25vh;
  }
}

p, label {
  @include mixins.text-p;

  &.fancy {
    font-family: variables.$font-serif;
    font-weight: 200;
  }
}

p + p {
  margin-top: variables.$card-spacing;
}

h1 {
  font-family: variables.$font-serif;
  font-weight: 200;
  font-size: 96px;
  line-height: 96px;
  text-align: center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.712);

  @include mixins.media("<mobile") {
    font-size: 70px;
    line-height: 70px;
  }
  &.long {
    @include mixins.media("<tablet-portrait") {
      display: none;
    }
  }
  &.long-broken-up {
    @include mixins.media(">tablet-portrait") {
      display: none;
    }
    @media (max-width: 500px) {
      font-size: 57px;
      line-height: 57px;
    }
  }

  &.small-print {
    font-size: 60px;
    line-height: 60px;
    @include mixins.media("<mobile") {
      font-size: 40px;
      line-height: 40px;
    }
  }
}

h2 {
  font-family: variables.$font-serif;
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;
  margin-bottom: variables.$card-spacing;

  .tiny {
    font-size: 20px;
  }
}

.h2-super {
  color: variables.$color-transp-text;
  font-family: variables.$font-sans-serif;
  font-style: normal;
  font-weight: 250;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.14rem;
  text-transform: uppercase;
  margin-bottom: variables.$card-spacing;
}

h3 {
  font-family: variables.$font-sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 131%;
  margin-bottom: 7px;
}

h4 {
  font-family: variables.$font-sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 3px;
}

a, .a {
  padding: 0;
  font-family: variables.$font-serif;
  font-weight: 200;
  text-decoration: underline;
  background: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }

  &.glow {
    @include mixins.text-p;
    font-weight: 500;
  }

  &.glow, &.button, &.hover-glow {
    &:not(.hover-glow) {
      text-shadow: variables.$glow-blue;
    }
    // transition: text-shadow $time-transition-medium ease;
    text-decoration: none;
    &:hover {
      text-shadow: variables.$glow-blue-hover;
    }
  }
}

// this is for things that one can click but can't be an a tag
.clickable {
  cursor: pointer;
  & > * {
    cursor: auto;
  }
}

strong {
  font-weight: 700;
}

button.glow, .button {
  padding: 15px 30px;
  padding-left: 60px;
  @include mixins.text-p;
  font-size: 18px;
  background-color: variables.$color-transp-panel;
  text-shadow: variables.$glow-blue;
  transition: background-color variables.$time-transition-medium ease;
  font-weight: 300;
  display: inline-block;
  position: relative;
  margin: initial auto;
  text-align: left;
  width: 100%;

  &::before {
    position: absolute;
    content: url(assets/arrow_down.svg);
    left: 12px;
    transform: translateY(-2px) rotate(-90deg) scale(.5);
    box-sizing: border-box;
    transform-origin: center center;
    filter: variables.$glow-blue-filter;
    // transition: filter $time-transition-medium ease;
  }

  &.back::before {
    transform: translateY(-2px) rotate(90deg) scale(.5);
  }

  &.checkbox {
    &::before {
      left: 18px;
      transform: unset;
    }
    &:not(.checked) {
      &::before {
        content: url(assets/checkbox_unchecked.svg);
        opacity: 0.5;
      }
    }
    &.checked {
      &::before {
        content: url(assets/checkbox_checked.svg);
      }
    }
  }

  &.back:not(.checkbox) {
    &::before {
      transform: translate(-5px, -2px) rotate(90deg) scale(.5);
    }
  }

  &:hover {
    background-color: variables.$color-transp-panel-hover;
    text-shadow: variables.$glow-blue-hover;
    &::before {
      filter: variables.$glow-blue-hover-filter;
    }
  }
  &:active {
    background-color: variables.$color-transp-panel-active;
  }
}

.transparent-panel, .card.transparent-panel {
  background-color: variables.$color-transp-panel-thick;
  padding: 15px 20px;
}

li {
  @include mixins.text-p;
}

ul.commalist li {
  display: inline-block;
}

ul.commalist li:nth-last-child(2) { 
  
  > span {
  display: none;
  }

  &:has(span)::after {
    display: inline-block !important;
    content: '\a0 en\a0';
  }
}

ul.commalist li:last-child span{
  display: none;
}

ul.columnlist {
  column-count: 2;
}

input, textarea, select {
  @include mixins.text-p;
  background-color: variables.$color-cool-darker-gray-day;
  border: none;
  padding: 4px 8px;
  font-size: 16px;
}

table {
  $table-border-padding: 20px;

  display: block;
  padding-left: calc($table-border-padding * 1 / 2);
  padding-right: calc($table-border-padding * 1 / 2);

  thead tr {
    th, td {
      padding-bottom: 20px;
      font-size: 17px;
    }
  }
  tr {
    th {
      padding-bottom: 20px;
      text-align: left;
      padding-right: 20px;
      label, p {
        @include mixins.text-p;
        font-weight: 500;
        @include mixins.media("<tablet-portrait") {
          font-size: 13px;
        }
      }
    }
    td {
      @include mixins.text-p;
      padding-left: calc($table-border-padding * 1.5 / 5);
      padding-right: calc($table-border-padding * 3.5 / 5);
    }
  }

  &:not(.form-table) tr + tr {
    td:first-child, th:first-child {
      padding-top: 10px;
    }
  }

  @include mixins.media("<tablet-portrait") {
    &, td, tr, th { display: block; text-align: left; }
    tr + tr { margin-top: 1.5em; }
    tr td + td { margin-left: 0px; padding-left: 0; }
    td:first-child {font-weight: bold;}
    td {padding-left:0 !important; padding-right:0;}
    td + td {padding-top:0.5em;}
    thead { display: none; }
  }
  
  input:not([type='checkbox']), select, textarea {
    width: 100%;
    margin-bottom: 4px;
  }

  &.form-table {
    tbody {
      display: block;
    }
    tr {
      td {
        line-height: 11px;
        width: 100%;
      }
    }
    
    @include mixins.media("<desktop") {
      tr th { padding-bottom: 6px; }
    }
  }
}

// Card / layout stuff

.card {
  position: relative;
  padding: 30px 40px;
  background-color: variables.$color-cool-dark-gray-day;
  height: auto;

  &.invert {
    background-color: variables.$color-cool-white;
    h1,h2,h3,h4,p,a,label,button {
      color: variables.$color-black;
    }
  }

  @include mixins.media("<mobile") {
    padding: 20px 30px;
  }

  .icon {
    position: absolute;
    @include mixins.media(">mobile") {
      top: 0;
      left: -50px;
      &.right {
        left: initial;
        right: -50px;
      }
    }
    @include mixins.media("<mobile") {
      bottom: 0px;
      right: 0px;
      transform: scale(0.6);
    }
  }

  &.glow-off .icon {
    filter: none;
  }

  img.cover {
    width: 100%;
    margin-top: variables.$card-spacing;
  }
}

.article-card {
  padding: 30px 40px;
  background-color: variables.$color-cool-dark-gray-day;

  &:has(h1.title) {
    padding: 10px 30px 40px 40px;
  }

  h1.title {
    font-size: 30px;
    text-align: left;
    width: 100%;
    display: block;
    padding: 0px;
    margin: 0px;
    
    //&::after { // line between title and content body
    //  display:block;
    //  content: "";
    //  background-color: #152248; // cheeky coloring because makeing it transparent is not fun...
    //  width: calc(100% + 80px); // add padding left and right
    //  transform: translate(-40px); //translate into padding
    //  height: 5px;
    //}

  }

  p, ul {
    margin-bottom: 14px;
  }

  ul {
    list-style: circle;
    padding-left: 30px;
  }

  h2 {
    font-size: 30px;
    line-height: 30px;
    margin: 35px 0 20px 0;
  }
}

.layout-spacer {
  + .layout-spacer {
    margin-top: variables.$layout-spacing;
  }
}

.vert-layout {
  &, &-spaced {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    
    &.align-start {
      align-items: flex-start;
    }
    &.align-stretch {
      align-items: stretch;
      button { width: 100%; }
    }
    
    > .align-stretch {
      align-self: stretch;
      max-width: unset;
    }
    @include mixins.media("<mobile") {
      > * {
        width: 100%;
      }
    }
  }

  @mixin align-start-end {
    > .align-start {
      align-self: flex-start;
      &.inbetween { margin-left: 10%; }
    }
    > .align-end {
      align-self: flex-end;
      &.inbetween { margin-right: 10%; }
    }
  }

  @include align-start-end();

  &-spaced {
    width: 100%;
    > * + * {
      margin-top: variables.$layout-spacing;
    }
    &.small-spacing > * + * {
      margin-top: variables.$card-spacing;
    }

    @include mixins.media(">tablet-portrait") {
      @include align-start-end();
    }
    
    &, .vert-layout, .expander {
      > .card {
        @include mixins.media(">desktop") {
          min-width: 500px;
          max-width: 600px;
        }
        @include mixins.media("<desktop") {
          width: 500px;
        }
        @include mixins.media("<mobile") {
          width: 100%;
        }
      }
    }
  }
}

.hor-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  // align-items: stretch;

  > * {
    flex-grow: 1;
    display: inline-flex;
  }
}

.grid-layout {
  width: 100%;
  display: grid;
  gap: variables.$layout-spacing;
  justify-items: center;
  
  .vert-layout-spaced > .card {
    min-width: unset;
    max-width: unset;
    width: unset;
  }

  @include mixins.media("<mobile") {
    grid-template: auto / minmax(0, 1fr);
    grid-auto-flow: row;
  }
}

.flow-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.freelayout-spacer {
  padding: 10px 20px 0;
  @include mixins.media(">mobile") {
    padding: 25px 100px 0;
  }
}

.topper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  flex-wrap: wrap;

  h2 {
    padding-top: 10px;
    display: block;
    text-align: center;
    max-width: calc(100vw - 30px);
    word-wrap: break-word;
  }
  .padder {
    width: 250px;
  }
}

// Misc

[v-cloak] {
  display: none;
}


*::selection {
  background: rgba(variables.$color-cool-gray, .5) !important; /* WebKit/Blink Browsers */
}

.unbulleted {
  list-style: none;
}

.icon {
  width: 40px;
  height: 40px;
  user-select: none;
  filter: variables.$glow-orange-filter;
  // transition: filter $time-transition-medium ease;

  &.off {
    filter: drop-shadow(variables.$drop-shadow-icon);
  }
}

.glow-first-item {
  :not(:first-child) .icon {
    filter: drop-shadow(variables.$drop-shadow-icon);
  }
}

.text-shadow-small {
  text-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.911);
}

.alert { /* TODO: fix this mess */
  color: green;
  border-size: 5px;
}
