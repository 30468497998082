@use "sass:math";
@use '../variables.scss';
@use '../mixins.scss';

$bonfire-state-edit-text-transform: translateX(29px);
$bonfire-state-edit-icon-hidden-transform: rotate(90deg) translate(47px, -10px) scale(0.8);
$bonfire-state-edit-icon-visible-transform: rotate(0deg) translate(47px, -10px) scale(0.8);

nav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;

  .background-blur img {
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    pointer-events: none;
    user-select: none;
    // margin: 0 0 -30px -30px;
  }

  ul {
    z-index: 11;
    bottom: 85px;
    left: 30px;
    position: absolute;
    width: 200px;

    li {
      list-style: none;
      position: relative;
  
      a {
        font-weight: 100;
        font-family: variables.$font-sans-serif;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-shadow: none;
        padding: 7px 0;
        width: 100%;
        text-transform: capitalize;

        .icon {
          width: 30px;
          height: 30px;
          margin-right: 15px;
          // transition: filter $time-transition-medium ease;
        }

        &:not(.selected) .icon {
          filter: none;
        }

        &:hover .icon {
          filter: variables.$glow-blue-filter;
        }
      }
    }
  }

  .bonfire-container {
    z-index: 11;
    position: absolute;
    left: 20px;
    bottom: 19px;
    
    .animation {
      position: relative;
      width: 50px;
      height: 50px;
      user-select: none;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .bonfire-fire {
        transform: scale(2, 2);
        pointer-events: none;
      }
      .bonfire-fire-glow {
        &-1, &-2 {
          transition-duration: variables.$time-transition-long;
          transition-timing-function: ease;
          transition-property: transform, opacity;
          pointer-events: none;
        }
        &-1 {
          transform: scale(2.6, 2.6);
          opacity: 0.8;
          filter: blur(7px);
          mix-blend-mode: hard-light;
        }
        &-2 {
          transform: scale(2.2, 2.2);
          opacity: 0.8;
          filter: blur(4px);
          mix-blend-mode: overlay;
        }
      }
    }

    .bonfire-cogwheel {
      transition: transform variables.$time-transition-medium ease-out;
    }

  }

  .bonfire-state {
    position: absolute;
    left: 67px;
    bottom: 0;
    padding: 20px;
    z-index: 10;
    width: 200px;

    .bonfire-state-content-wrapper {
      transform: translateX(0);
      position: relative;
      width: auto;
      display: block;
      transition: transform variables.$time-transition-medium ease;

      .bonfire-state-text-wrapper {
        display: block;
        position: relative;
        height: 20px;
  
        .bonfire-state-text-short, .bonfire-state-text-full, .bonfire-state-text-prefix {
          white-space: nowrap;
          text-shadow: 0 0 8px black;
          font-family: variables.$font-serif;
          font-weight: 100;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          display: block;
        }
        .bonfire-state-text-short-wrapper, .bonfire-state-text-full-wrapper {
          transition: opacity variables.$time-transition-long;
          position: absolute;
          &::before {
            content: "";
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            z-index: -1;
            background-color: black;
            filter: blur(27px);
          }
        }

        .bonfire-state-text-short-wrapper {
          opacity: 1;
          &::before {
            width: calc(100% + 40px);
            height: calc(100% + 40px);
          }
          .bonfire-state-text-short {
            max-width: MIN(200px, 100vw - 140px);
          }
        }
  
        .bonfire-state-text-full-wrapper {
          opacity: 0;
          pointer-events: none;
          &::before {
            width: calc(100% + 80px);
            height: calc(100% + 70px);
          }
          .bonfire-state-text-full {
            max-width: calc(100vw - 140px);
          }
        }

        .bonfire-state-text-prefix {
          position: absolute;
          font-size: 11px;
          left: 0;
          top: -18px;
          color: rgba(255, 255, 255, 0.685);
          font-style: italic;
          pointer-events: none;
        }
      }
  
      .bonfire-state-author {
        font-family: variables.$font-sans-serif;
        opacity: 0.3;
        font-style: italic;
        display: block;
        margin-top: 2px;
      }
    }

    .bonfire-state-edit-btn {
      position: absolute;
      top: 18px;
      left: -42px;
      transform: $bonfire-state-edit-icon-hidden-transform;
      transition: transform variables.$time-transition-medium ease, opacity variables.$time-transition-medium ease;
      opacity: 0;
      z-index: 1;
    }

    &.authenticated {
      &:hover {
        .bonfire-state-content-wrapper {
          transform: $bonfire-state-edit-text-transform;
        }
        .bonfire-state-edit-btn {
          transform: $bonfire-state-edit-icon-visible-transform;
          opacity: 1;
          &:hover {
            cursor: pointer;
            filter: variables.$glow-blue-filter;
          }
        }
      }
    }
  }

  @include mixins.media("fine-pointer") {
    &:hover {
      .bonfire-container .animation .bonfire-fire-glow {
        &-1, &-2 {
          filter: blur(1px) variables.$glow-orange-filter;
          opacity: 0.6;
          transform: scale(2, 2);
        }
      }
    }
  }

  .nav-item-text, .nav-bg-legend {
    transition: opacity variables.$time-transition-long ease, transform variables.$time-transition-long ease;
    opacity: 0;
  }
  .nav-item-text {
    transform: translateX(3px);
  }
  .nav-bg-legend {
    transform: scale(1.5, 1.2);
    transform-origin: bottom left;
  }

  @include mixins.media(">tablet-portrait") {
    &:hover {
      .nav-item-text, .nav-bg-legend {
        opacity: 1;
      }
      .nav-item-text {
        transform: translateX(0);
      }
      .nav-bg-legend {
        transform: scale(1, 1);
      }

      .bonfire-state {
        .bonfire-state-content-wrapper {
          .bonfire-state-text-short-wrapper {
            opacity: 0;
          }
          .bonfire-state-text-full-wrapper {
            opacity: 1;
          }
        }
      }
    }
  }

  .nav-bg-mobile {
    transform: scale(0.3);
    transform-origin: bottom left;
    @include mixins.media(">tablet-portrait") {
      display: none;
    }
  }

  @include mixins.media("<tablet-portrait") {
    .nav-bg-icons, .nav-bg-legend, .legend {
      opacity: 0;
      pointer-events: none;
      transition: opacity variables.$time-transition-long ease, transform variables.$time-transition-long ease;
    }

    .bonfire-state {
      transition: opacity variables.$time-transition-long ease;
      opacity: 0;

      // Set the campfire state to how it otherwise would be on hover

      .bonfire-state-edit-btn {
        transform: $bonfire-state-edit-icon-visible-transform;
      }
      .bonfire-state-content-wrapper .bonfire-state-text-wrapper {
        .bonfire-state-text-short-wrapper {
          opacity: 0;
        }
        .bonfire-state-text-full-wrapper {
          opacity: 1;
        }
      }

      &.authenticated {
        .bonfire-state-content-wrapper {
          transform: $bonfire-state-edit-text-transform;
        }
        .bonfire-state-edit-btn {
          opacity: 1;
        }
      }
    }

    &.mobile-expanded {
      .nav-bg-icons, .nav-bg-legend, .legend {
        opacity: 1;
        pointer-events: all;
      }

      .nav-item-text {
        opacity: 1;
        transform: translateX(0);
      }

      .nav-bg-legend {
        transform: scale(1.2, 1.1);
      }

      .bonfire-cogwheel {
        transform: rotate(90deg);
      }

      .bonfire-state {
        opacity: 1;
      }

      @include mixins.media("fine-pointer") {
        .bonfire-container:hover .bonfire-cogwheel {
          transform: rotate(75deg);
        }
      }
    }

    &:not(.mobile-expanded) {
      .bonfire-state {
        pointer-events: none;
      }
    }

    .bonfire-container:hover {
      cursor: pointer;
      @include mixins.media("fine-pointer") {
        .bonfire-cogwheel {
          transform: rotate(15deg);
        }
      }
    }
  }
}
