#balance-assumption-list {
	list-style-type: square;
	list-style-position: inside;
}

#saldo-form-grid {
	textarea {
		width: 100%;
		box-sizing: border-box;
	}
	p {
		padding-bottom: 25px
	}
	.grid-layout {
		grid-template: auto auto / 1fr 1fr;
		gap: 15px;
		li {
			width: 100%;
		}

		input, select {
			width: 100%;
			box-sizing: border-box;
		}
		p {
			margin-top: 0;
		}
		label {
			font-size: 13px;
		}
	}

}

.negativebalance {
    color: red
}
