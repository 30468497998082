.image-carousel {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 250px;
  max-width: 100vw;
  scrollbar-width: none;

  img {
    height: 100%;
    display: inline-block;
    + img {
      margin-left: 20px;
    }
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }
}
