@use '../variables.scss';
@use '../mixins.scss';

form {
  .error {
    color: variables.$color-fiery-orange;
    margin-bottom: 4px;
  }
  .errorlist {
    li {
      color: variables.$color-fiery-orange;
      margin-bottom: 4px;
    }
    & + input {
      border: 1px solid variables.$color-fiery-orange;
    }
  }
  li {
    list-style-type: none;
  }

  .helptext {
    @include mixins.text-p;
    font-size: 11px;
    line-height: 11px;
    margin-bottom: 8px;
    display: block;
  }

}
