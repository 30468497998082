@use "../variables";

$size: 50px;
$gutter: 10px;

.pagination .step-links {
  height: $size;
  line-height: $size;
  display: flex;
  flex-direction: horizontal;

  > * + * {
    margin-left: $gutter;
  }
  
  .paginate-btn {
    height: $size;
    width: $size;
    padding: 0;

    @mixin btn-arrow {
      position: absolute;
      content: url(assets/arrow_down.svg);
      left: 50%;
      top: 50%;
      box-sizing: border-box;
      transform-origin: center center;
      filter: variables.$glow-blue-filter;
      transition: transform variables.$time-transition-medium ease;
    }

    --scale: 1;
    &.prev {
      --scale: -1;
    }

    --left: 0;
    &:hover {
      --left: 10%;
      &.extreme {
        --left: 20%;
      }
    }
    
    --left-2: 20%;
    &:hover {
      --left-2: 0;
    }

    &::before {
      @include btn-arrow;
      transform: translate(-50%, -50%) scale(var(--scale), 1) translateX(var(--left)) scale(.5) rotate(-90deg);
    }
    &.extreme::after {
      @include btn-arrow;
      transform: translate(-50%, -50%) scale(var(--scale), 1) translateX(var(--left-2)) scale(.5) rotate(-90deg);
    }
  }
  
  .paginate-btns-placeholder {
    height: $size;
    width: #{$size * 2 + $gutter};
  }
}
