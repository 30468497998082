.owee-banner {
  position: fixed;
  top: 0px;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  height: 350px;
  z-index: 10;
  cursor: pointer;
  min-width: 700px;

  @media screen and (max-width: 700px) {
    height: 300px;
  }

  canvas {
    width: 100%;
    height: 100%;
  }

}
