@use "../variables";

.quick-buttons {
  top: 10px;
  right: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  > a {
    user-select: none;
    margin-bottom: 5px;
    &:hover {
      filter: variables.$glow-blue-filter;
    }
  }

  .delete-button::after {
    content: url(assets/cross.svg);
  }
  .edit-button::after {
    content: url(assets/edit.svg);
  }
  .enlarge-button::after {
    content: url(assets/enlarge.svg);
  }
  .select-button-on::after {
    content: url(assets/checkmark_checked.svg)
  }
  .select-button-off::after {
    content: url(assets/checkmark_unchecked.svg)
  }
  .eye-button::after {
    content: url(assets/eye.svg);
    filter: variables.$glow-blue-filter;
  }
  .eye-button:hover::after {
    content: url(assets/eye_strikethough.svg);
  }
  .eyestrikethough-button::after {
    content: url(assets/eye_strikethough.svg);
  }
  .eyestrikethough-button:hover::after {
    content: url(assets/eye.svg);
  }
  .star-button::after {
    content: url(assets/star.svg);
    filter: variables.$glow-blue-filter;
  }
  .star-button:hover::after {
    content: url(assets/star_outline.svg);
  }
  .staroutline-button::after {
    content: url(assets/star_outline.svg);
  }
  .staroutline-button:hover::after {
    content: url(assets/star.svg);
  }
}
