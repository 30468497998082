@use "sass:color";
@use '../variables.scss';

.expandable.spaced > *:first-child {
  margin-top: variables.$card-spacing;
}

.expander {
  .card {
    padding-right: 70px;
    transition: background-color variables.$time-transition-medium ease;

    h2 {
      margin-bottom: 0;
    }
  }

  .card {
    &::before {
      position: absolute;
      content: url(assets/arrow_down.svg);
      right: 10px;
      top: 50%;
      transform: translate(0, -50%) scale(.5);
      box-sizing: border-box;
      transform-origin: center center;
      transition: transform variables.$time-transition-medium ease-out;
    }
  }
  &:not(.expanded) .card {
    &:hover {
      cursor: pointer;
      background-color: color.adjust(variables.$color-cool-dark-gray-day, $lightness: 5%);
    }
    &:active {
      background-color: color.adjust(variables.$color-cool-dark-gray-day, $lightness: 10%);
    }
  }

  .expandable {
    max-height: 0;
    opacity: 0;
    transition: max-height variables.$time-transition-medium ease-out, opacity variables.$time-transition-medium ease-out;
    overflow: hidden;
  }

  &.expanded {
    .card::before {
      transform: translate(0, -50%) scale(.5) rotate(180deg);
    }
    .expandable {
      opacity: 1;
    }
  }
  
  .close-btn {
    position: absolute;
    width: 60px;
    height: 100%;
    right: 0;
    top: 0;
    background: #ffffff00;
    transition: background-color variables.$time-transition-medium ease;
    padding: 0;
    pointer-events: none;
  }
  &.expanded .close-btn {
    pointer-events: all;

    &:hover {
      background-color: #acbbff10;
    }
    &:active {
      background-color: #acbbff23;
    }
  }
}
