@use "sass:color";
@use "sass:math";
@use '../variables.scss';
@use "../mixins";

#meals {
  .meal-description {
    font-size: 18px;
    font-family: variables.$font-serif;
    margin-top: 2px;
    font-style: italic;
    font-weight: 100;
  }

  .meal-deadline {
    font-size: 15px;
    font-weight: 100;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    text-shadow: variables.$glow-blue;
  }

  .side-panel {
    input {
      width: 100%;
      background-color: variables.$color-transp-panel-thick;
    }

    label {
      font-size: 14px;
    }

    .diet {
      th, td {
        display: block;
      }
      th {
        padding-bottom: 8px;
      }
    }

    .auto-meal {
      .title {
        font-size: 17px;
        margin-bottom: 10px;
        font-weight: 100;
      }
      .auto-meal-field {
        display: inline-flex;
        flex-direction: row;
        height: 40px;
        position: relative;
        label {
          padding: 0;
          padding: 10px 15px 10px 5px;
          font-size: 15px;
        }
        input {
          height: 40px;
          width: 40px;
          opacity: 0;
        }
        .checkbox {
          &::after {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 20px;
            top: 21px;
            pointer-events: none;
            user-select: none;
            content: url(assets/checkbox_unchecked.svg);
          }
        }
        input:checked {
          ~ label {
            text-shadow: variables.$glow-blue;

          }
          ~ .checkbox::after {
            content: url(assets/checkbox_checked.svg);
            filter: variables.$glow-blue-filter;
          }
        }

        transition: background-color variables.$time-transition-medium ease;
        &:hover {
          background-color: color.adjust(variables.$color-transp-panel-hover, $lightness: 20%);
          &::before {
            filter: variables.$glow-blue-hover-filter;
          }
        }
        label, input {
          &:hover {
            cursor: pointer;
          }
        }
        &:active {
          background-color: color.adjust(variables.$color-transp-panel-active, $lightness: 20%);
        }
      }
    }
    
    @include mixins.media(">desktop") {
      position: fixed;
      top: 80px; // 196px for align with first card
      right: 50px;
      width: MAX(210px, MIN(300px, 50vw - 520px));

      .vert-layout {
        width: 100%;
      }


      .card {
        min-width: unset;
        max-width: unset;
        width: 100%;
      }
    }
  }
}
