@use "../variables.scss";

/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left; 
    margin: 1px;
    background-color: black;
    background-position: center;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkmark {
    background-image: url("assets/checkbox_checked.svg");
  }

  .checkmark:after {
    width: 30px;
    height: 30px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.albumselect {
  // keep 1:1 aspect ratio
  padding-bottom: 100%;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;

  &:hover {
      filter: variables.$glow-blue-hover-filter;
  }

  &:has(input:checked) { 
    outline-color: lightblue;
    outline-style: solid;
    outline-width: 1px;
    filter: variables.$glow-blue-filter;
  }
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 22px;
  width: 22px;
  background-image: url("assets/checkbox_unchecked.svg");
  background-size: cover;

  &:hover {
    filter: variables.$glow-blue-filter;
  }
}
