@use "../variables";

.icon-btn-inline {
  display: inline;
  cursor: pointer;
  padding-right: 3px;
  &::after {
    max-height: 14px;
    display: inline-block;
    transform: scale(0.9);
    vertical-align: bottom;
    padding-bottom: 4px;
  }
  &:hover::after {
    filter: variables.$glow-blue-filter
  }
}


.icon-plus {
  &::after {
    content: url(assets/plus.svg);
  }
  &.icon-btn-inline::after {
    transform: scale(0.8);
  }
}
.icon-select::after {
  content: url(assets/checkbox_unchecked.svg)
}
.icon-edit::after {
  content: url(assets/edit.svg);
}
.icon-delete::after {
  content: url(assets/cross.svg);
}
.icon-eye::after {
  content: url(assets/eye.svg);
  filter: variables.$glow-blue-filter;
}
.icon-eyestrikethough::after {
  content: url(assets/eye_strikethough.svg);
}
.icon-star::after {
  content: url(assets/star.svg);
  filter: variables.$glow-blue-filter;
}
.icon-staroutline::after {
  content: url(assets/star_outline.svg);
}
