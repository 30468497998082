@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600&display=swap";
:root {
  --f-spinner-width: 36px;
  --f-spinner-height: 36px;
  --f-spinner-color-1: #0000001a;
  --f-spinner-color-2: #11181ccc;
  --f-spinner-stroke: 2.75;
}

.f-spinner {
  width: var(--f-spinner-width);
  height: var(--f-spinner-height);
  margin: auto;
  padding: 0;
}

.f-spinner svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
  animation: 2s linear infinite f-spinner-rotate;
}

.f-spinner svg * {
  stroke-width: var(--f-spinner-stroke);
  fill: none;
}

.f-spinner svg :first-child {
  stroke: var(--f-spinner-color-1);
}

.f-spinner svg :last-child {
  stroke: var(--f-spinner-color-2);
  animation: 2s ease-in-out infinite f-spinner-dash;
}

@keyframes f-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes f-spinner-dash {
  0% {
    stroke-dasharray: 1 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124px;
  }
}

.f-throwOutUp {
  animation: var(--f-throw-out-duration, .175s) ease-out both f-throwOutUp;
}

.f-throwOutDown {
  animation: var(--f-throw-out-duration, .175s) ease-out both f-throwOutDown;
}

@keyframes f-throwOutUp {
  to {
    transform: translate3d(0, calc(var(--f-throw-out-distance, 150px) * -1), 0);
    opacity: 0;
  }
}

@keyframes f-throwOutDown {
  to {
    transform: translate3d(0, var(--f-throw-out-distance, 150px), 0);
    opacity: 0;
  }
}

.f-zoomInUp {
  animation: var(--f-transition-duration, .2s) ease .1s both f-zoomInUp;
}

.f-zoomOutDown {
  animation: var(--f-transition-duration, .2s) ease both f-zoomOutDown;
}

@keyframes f-zoomInUp {
  from {
    opacity: 0;
    transform: scale(.975)translate3d(0, 16px, 0);
  }

  to {
    opacity: 1;
    transform: scale(1)translate3d(0, 0, 0);
  }
}

@keyframes f-zoomOutDown {
  to {
    opacity: 0;
    transform: scale(.975)translate3d(0, 16px, 0);
  }
}

.f-fadeIn {
  animation: var(--f-transition-duration, .2s) var(--f-transition-easing, ease) var(--f-transition-delay, 0s) both f-fadeIn;
  z-index: 2;
}

.f-fadeOut {
  animation: var(--f-transition-duration, .2s) var(--f-transition-easing, ease) var(--f-transition-delay, 0s) both f-fadeOut;
  z-index: 1;
}

@keyframes f-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeOut {
  100% {
    opacity: 0;
  }
}

.f-fadeFastIn {
  animation: var(--f-transition-duration, .2s) ease-out both f-fadeFastIn;
  z-index: 2;
}

.f-fadeFastOut {
  animation: var(--f-transition-duration, .1s) ease-out both f-fadeFastOut;
  z-index: 2;
}

@keyframes f-fadeFastIn {
  0% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeFastOut {
  100% {
    opacity: 0;
  }
}

.f-fadeSlowIn {
  animation: var(--f-transition-duration, .5s) ease both f-fadeSlowIn;
  z-index: 2;
}

.f-fadeSlowOut {
  animation: var(--f-transition-duration, .5s) ease both f-fadeSlowOut;
  z-index: 1;
}

@keyframes f-fadeSlowIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeSlowOut {
  100% {
    opacity: 0;
  }
}

.f-crossfadeIn {
  animation: var(--f-transition-duration, .2s) ease-out both f-crossfadeIn;
  z-index: 2;
}

.f-crossfadeOut {
  animation: calc(var(--f-transition-duration, .2s) * .5) linear .1s both f-crossfadeOut;
  z-index: 1;
}

@keyframes f-crossfadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-crossfadeOut {
  100% {
    opacity: 0;
  }
}

.f-slideIn.from-next {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-slideInNext;
}

.f-slideIn.from-prev {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-slideInPrev;
}

.f-slideOut.to-next {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-slideOutNext;
}

.f-slideOut.to-prev {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-slideOutPrev;
}

@keyframes f-slideInPrev {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-slideInNext {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-slideOutNext {
  100% {
    transform: translateX(-100%);
  }
}

@keyframes f-slideOutPrev {
  100% {
    transform: translateX(100%);
  }
}

.f-classicIn.from-next {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-classicInNext;
  z-index: 2;
}

.f-classicIn.from-prev {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-classicInPrev;
  z-index: 2;
}

.f-classicOut.to-next {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-classicOutNext;
  z-index: 1;
}

.f-classicOut.to-prev {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-classicOutPrev;
  z-index: 1;
}

@keyframes f-classicInNext {
  0% {
    opacity: 0;
    transform: translateX(-75px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-classicInPrev {
  0% {
    opacity: 0;
    transform: translateX(75px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-classicOutNext {
  100% {
    opacity: 0;
    transform: translateX(-75px);
  }
}

@keyframes f-classicOutPrev {
  100% {
    opacity: 0;
    transform: translateX(75px);
  }
}

:root {
  --f-button-width: 40px;
  --f-button-height: 40px;
  --f-button-border: 0;
  --f-button-border-radius: 0;
  --f-button-color: #374151;
  --f-button-bg: #f8f8f8;
  --f-button-hover-bg: #e0e0e0;
  --f-button-active-bg: #d0d0d0;
  --f-button-shadow: none;
  --f-button-transition: all .15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 20px;
  --f-button-svg-height: 20px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: none;
  --f-button-svg-disabled-opacity: .65;
}

.f-button {
  box-sizing: content-box;
  width: var(--f-button-width);
  height: var(--f-button-height);
  border: var(--f-button-border);
  border-radius: var(--f-button-border-radius);
  color: var(--f-button-color);
  background: var(--f-button-bg);
  box-shadow: var(--f-button-shadow);
  pointer-events: all;
  cursor: pointer;
  transition: var(--f-button-transition);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .f-button:hover:not([disabled]) {
    color: var(--f-button-hover-color);
    background-color: var(--f-button-hover-bg);
  }
}

.f-button:active:not([disabled]) {
  background-color: var(--f-button-active-bg);
}

.f-button:focus:not(:focus-visible) {
  outline: none;
}

.f-button:focus-visible {
  box-shadow: inset 0 0 0 var(--f-button-outline, 2px) var(--f-button-outline-color, var(--f-button-color));
  outline: none;
}

.f-button svg {
  width: var(--f-button-svg-width);
  height: var(--f-button-svg-height);
  fill: var(--f-button-svg-fill);
  stroke: currentColor;
  stroke-width: var(--f-button-svg-stroke-width);
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: var(--f-button-transform);
  filter: var(--f-button-svg-filter);
  pointer-events: none;
  transition: opacity .15s;
}

.f-button[disabled] {
  cursor: default;
}

.f-button[disabled] svg {
  opacity: var(--f-button-svg-disabled-opacity);
}

.f-carousel__nav .f-button.is-prev, .f-carousel__nav .f-button.is-next, .fancybox__nav .f-button.is-prev, .fancybox__nav .f-button.is-next {
  z-index: 1;
  position: absolute;
}

.is-horizontal .f-carousel__nav .f-button.is-prev, .is-horizontal .f-carousel__nav .f-button.is-next, .is-horizontal .fancybox__nav .f-button.is-prev, .is-horizontal .fancybox__nav .f-button.is-next {
  top: 50%;
  transform: translateY(-50%);
}

.is-horizontal .f-carousel__nav .f-button.is-prev, .is-horizontal .fancybox__nav .f-button.is-prev {
  left: var(--f-button-prev-pos);
}

.is-horizontal .f-carousel__nav .f-button.is-next, .is-horizontal .fancybox__nav .f-button.is-next {
  right: var(--f-button-next-pos);
}

.is-horizontal.is-rtl .f-carousel__nav .f-button.is-prev, .is-horizontal.is-rtl .fancybox__nav .f-button.is-prev {
  left: auto;
  right: var(--f-button-next-pos);
}

.is-horizontal.is-rtl .f-carousel__nav .f-button.is-next, .is-horizontal.is-rtl .fancybox__nav .f-button.is-next {
  right: auto;
  left: var(--f-button-prev-pos);
}

.is-vertical .f-carousel__nav .f-button.is-prev, .is-vertical .f-carousel__nav .f-button.is-next, .is-vertical .fancybox__nav .f-button.is-prev, .is-vertical .fancybox__nav .f-button.is-next {
  top: auto;
  left: 50%;
  transform: translateX(-50%);
}

.is-vertical .f-carousel__nav .f-button.is-prev, .is-vertical .fancybox__nav .f-button.is-prev {
  top: var(--f-button-next-pos);
}

.is-vertical .f-carousel__nav .f-button.is-next, .is-vertical .fancybox__nav .f-button.is-next {
  bottom: var(--f-button-next-pos);
}

.is-vertical .f-carousel__nav .f-button.is-prev svg, .is-vertical .f-carousel__nav .f-button.is-next svg, .is-vertical .fancybox__nav .f-button.is-prev svg, .is-vertical .fancybox__nav .f-button.is-next svg {
  transform: rotate(90deg);
}

.f-carousel__nav .f-button:disabled, .fancybox__nav .f-button:disabled {
  pointer-events: none;
}

html.with-fancybox {
  scroll-behavior: auto;
  width: auto;
  overflow: visible;
}

html.with-fancybox body {
  touch-action: none;
}

html.with-fancybox body.hide-scrollbar {
  width: auto;
  margin-right: calc(var(--fancybox-body-margin, 0px)  + var(--fancybox-scrollbar-compensate, 0px));
  overscroll-behavior-y: none;
  overflow: hidden !important;
}

.fancybox__container {
  --fancybox-color: #dbdbdb;
  --fancybox-hover-color: #fff;
  --fancybox-bg: #18181bfa;
  --fancybox-slide-gap: 10px;
  --f-spinner-width: 50px;
  --f-spinner-height: 50px;
  --f-spinner-color-1: #ffffff1a;
  --f-spinner-color-2: #bbb;
  --f-spinner-stroke: 3.65;
  box-sizing: border-box;
  color: #f8f8f8;
  -webkit-tap-highlight-color: #0000;
  z-index: var(--fancybox-zIndex, 1050);
  transform-origin: 0 0;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overscroll-behavior-y: contain;
  direction: ltr;
  outline: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: visible;
}

.fancybox__container *, .fancybox__container :before, .fancybox__container :after {
  box-sizing: inherit;
}

.fancybox__container::backdrop {
  background-color: #0000;
}

.fancybox__backdrop {
  z-index: -1;
  background: var(--fancybox-bg);
  opacity: var(--fancybox-opacity, 1);
  will-change: opacity;
  position: fixed;
  inset: 0;
}

.fancybox__carousel {
  box-sizing: border-box;
  z-index: 10;
  flex: 1;
  min-height: 0;
  position: relative;
  overflow: clip visible;
}

.fancybox__viewport {
  width: 100%;
  height: 100%;
}

.fancybox__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.fancybox__viewport.is-dragging {
  cursor: move;
  cursor: grabbing;
}

.fancybox__track {
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.fancybox__slide {
  width: 100%;
  height: 100%;
  margin: 0 var(--fancybox-slide-gap) 0 0;
  overscroll-behavior: contain;
  backface-visibility: hidden;
  flex-direction: column;
  flex: none;
  align-items: center;
  padding: 4px;
  display: flex;
  position: relative;
  overflow: auto;
  transform: translate3d(0, 0, 0);
}

.fancybox__container:not(.is-compact) .fancybox__slide.has-close-btn {
  padding-top: 40px;
}

.fancybox__slide.has-iframe, .fancybox__slide.has-video, .fancybox__slide.has-html5video, .fancybox__slide.has-image {
  overflow: hidden;
}

.fancybox__slide.has-image.is-animating, .fancybox__slide.has-image.is-selected {
  overflow: visible;
}

.fancybox__slide:before, .fancybox__slide:after {
  content: "";
  flex: 0 0 0;
  margin: auto;
}

.fancybox__backdrop:empty, .fancybox__viewport:empty, .fancybox__track:empty, .fancybox__slide:empty {
  display: block;
}

.fancybox__content {
  max-width: 100%;
  color: var(--fancybox-content-color, #374151);
  background: var(--fancybox-content-bg, #fff);
  cursor: default;
  z-index: 20;
  border-radius: 0;
  flex-direction: column;
  align-self: center;
  margin: 0;
  padding: 2rem;
  display: flex;
  position: relative;
}

.is-loading .fancybox__content {
  opacity: 0;
}

.is-draggable .fancybox__content {
  cursor: move;
  cursor: grab;
}

.can-zoom_in .fancybox__content {
  cursor: zoom-in;
}

.can-zoom_out .fancybox__content {
  cursor: zoom-out;
}

.is-dragging .fancybox__content {
  cursor: move;
  cursor: grabbing;
}

.fancybox__content [data-selectable], .fancybox__content [contenteditable] {
  cursor: auto;
}

.fancybox__slide.has-image > .fancybox__content {
  backface-visibility: hidden;
  background: center / contain no-repeat;
  min-height: 1px;
  padding: 0;
  transition: none;
  transform: translate3d(0, 0, 0);
}

.fancybox__slide.has-image > .fancybox__content > picture > img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.is-animating .fancybox__content, .is-dragging .fancybox__content {
  will-change: transform, width, height;
}

.fancybox-image {
  object-fit: contain;
  -webkit-user-select: none;
  user-select: none;
  filter: blur();
  width: 100%;
  height: 100%;
  min-height: 0;
  margin: auto;
  display: block;
}

.fancybox__caption {
  overflow-wrap: anywhere;
  max-width: 100%;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  cursor: auto;
  visibility: visible;
  flex-shrink: 0;
  align-self: center;
  margin: 0;
  padding: 14px 0 4px;
  line-height: 1.375;
}

.is-loading .fancybox__caption, .is-closing .fancybox__caption {
  opacity: 0;
  visibility: hidden;
}

.is-compact .fancybox__caption {
  padding-bottom: 0;
}

.f-button.is-close-btn {
  --f-button-svg-stroke-width: 2;
  z-index: 40;
  position: absolute;
  top: 0;
  right: 8px;
}

.fancybox__content > .f-button.is-close-btn {
  --f-button-width: 34px;
  --f-button-height: 34px;
  --f-button-border-radius: 4px;
  --f-button-color: var(--fancybox-color, #fff);
  --f-button-hover-color: var(--fancybox-color, #fff);
  --f-button-bg: transparent;
  --f-button-hover-bg: transparent;
  --f-button-active-bg: transparent;
  --f-button-svg-width: 22px;
  --f-button-svg-height: 22px;
  opacity: .75;
  position: absolute;
  top: -38px;
  right: 0;
}

.is-loading .fancybox__content > .f-button.is-close-btn, .is-zooming-out .fancybox__content > .f-button.is-close-btn {
  visibility: hidden;
}

.fancybox__content > .f-button.is-close-btn:hover {
  opacity: 1;
}

.fancybox__footer {
  margin: 0;
  padding: 0;
  position: relative;
}

.fancybox__footer .fancybox__caption {
  width: 100%;
  opacity: var(--fancybox-opacity, 1);
  padding: 24px;
  transition: all .25s;
}

.is-compact .fancybox__footer {
  z-index: 20;
  background: #18181b80;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.is-compact .fancybox__footer .fancybox__caption {
  padding: 12px;
}

.is-compact .fancybox__content > .f-button.is-close-btn {
  --f-button-border-radius: 50%;
  --f-button-color: #fff;
  --f-button-hover-color: #fff;
  --f-button-outline-color: #000;
  --f-button-bg: #0009;
  --f-button-active-bg: #0009;
  --f-button-hover-bg: #0009;
  --f-button-svg-width: 18px;
  --f-button-svg-height: 18px;
  --f-button-svg-filter: none;
  top: 5px;
  right: 5px;
}

.fancybox__nav {
  --f-button-width: 50px;
  --f-button-height: 50px;
  --f-button-border: 0;
  --f-button-border-radius: 50%;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: transparent;
  --f-button-hover-bg: #18181b4d;
  --f-button-active-bg: #18181b80;
  --f-button-shadow: none;
  --f-button-transition: all .15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 26px;
  --f-button-svg-height: 26px;
  --f-button-svg-stroke-width: 2.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: drop-shadow(1px 1px 1px #18181b80);
  --f-button-svg-disabled-opacity: .65;
  --f-button-next-pos: 1rem;
  --f-button-prev-pos: 1rem;
  opacity: var(--fancybox-opacity, 1);
}

.fancybox__nav .f-button:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: -30px -20px;
}

.is-idle .fancybox__nav {
  animation: .15s ease-out both f-fadeOut;
}

.is-idle.is-compact .fancybox__footer {
  pointer-events: none;
  animation: .15s ease-out both f-fadeOut;
}

.fancybox__slide > .f-spinner {
  margin: var(--f-spinner-top, calc(var(--f-spinner-width) * -.5)) 0 0 var(--f-spinner-left, calc(var(--f-spinner-height) * -.5));
  z-index: 30;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
}

.fancybox-protected {
  z-index: 40;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  inset: 0;
}

.fancybox-ghost {
  object-fit: contain;
  z-index: 40;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.fancybox-focus-guard {
  opacity: 0;
  pointer-events: none;
  outline: none;
  position: fixed;
}

.fancybox__container:not([aria-hidden]) {
  opacity: 0;
}

.fancybox__container.is-animated[aria-hidden="false"] > :not(.fancybox__backdrop, .fancybox__carousel), .fancybox__container.is-animated[aria-hidden="false"] .fancybox__carousel > :not(.fancybox__viewport), .fancybox__container.is-animated[aria-hidden="false"] .fancybox__slide > :not(.fancybox__content) {
  animation: var(--f-interface-enter-duration, .25s) ease .1s backwards f-fadeIn;
}

.fancybox__container.is-animated[aria-hidden="false"] .fancybox__backdrop {
  animation: var(--f-backdrop-enter-duration, .35s) ease backwards f-fadeIn;
}

.fancybox__container.is-animated[aria-hidden="true"] > :not(.fancybox__backdrop, .fancybox__carousel), .fancybox__container.is-animated[aria-hidden="true"] .fancybox__carousel > :not(.fancybox__viewport), .fancybox__container.is-animated[aria-hidden="true"] .fancybox__slide > :not(.fancybox__content) {
  animation: var(--f-interface-exit-duration, .15s) ease forwards f-fadeOut;
}

.fancybox__container.is-animated[aria-hidden="true"] .fancybox__backdrop {
  animation: var(--f-backdrop-exit-duration, .35s) ease forwards f-fadeOut;
}

.has-iframe .fancybox__content, .has-map .fancybox__content, .has-pdf .fancybox__content, .has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  flex-shrink: 1;
  max-width: 100%;
  min-height: 1px;
  overflow: visible;
}

.has-iframe .fancybox__content, .has-map .fancybox__content, .has-pdf .fancybox__content {
  width: calc(100% - 120px);
  height: 90%;
}

.fancybox__container.is-compact .has-iframe .fancybox__content, .fancybox__container.is-compact .has-map .fancybox__content, .fancybox__container.is-compact .has-pdf .fancybox__content {
  width: 100%;
  height: 100%;
}

.has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  width: 960px;
  max-width: 100%;
  height: 540px;
  max-height: 100%;
}

.has-map .fancybox__content, .has-pdf .fancybox__content, .has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  color: #fff;
  background: #18181be6;
  padding: 0;
}

.has-map .fancybox__content {
  background: #e5e3df;
}

.fancybox__html5video, .fancybox__iframe {
  background: none;
  border: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.fancybox-placeholder {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.f-carousel__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-outline: 0;
  --f-thumb-outline-color: #5eb0ef;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
  --f-thumb-border-radius: 2px;
  --f-thumb-offset: 0px;
  --f-button-next-pos: 0;
  --f-button-prev-pos: 0;
}

.f-carousel__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: .5;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
}

.f-carousel__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 16px;
  --f-thumb-clip-width: 46px;
}

.f-thumbs {
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  perspective: 1000px;
  flex: none;
  margin: 0;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.f-thumbs .f-spinner {
  z-index: -1;
  background-image: linear-gradient(#ebeff2, #e2e8f0);
  border-radius: 2px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.f-thumbs .f-spinner svg {
  display: none;
}

.f-thumbs.is-vertical {
  height: 100%;
}

.f-thumbs__viewport {
  width: 100%;
  height: auto;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.f-thumbs__track {
  display: flex;
}

.f-thumbs__slide {
  box-sizing: content-box;
  width: var(--f-thumb-width);
  height: var(--f-thumb-height);
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.f-thumbs__slide.is-loading img {
  opacity: 0;
}

.is-classic .f-thumbs__viewport {
  height: 100%;
}

.is-modern .f-thumbs__track {
  width: max-content;
}

.is-modern .f-thumbs__track:before {
  content: "";
  top: 0;
  bottom: 0;
  left: calc((var(--f-thumb-clip-width, 0)) * -.5);
  width: calc(var(--width, 0) * 1px + var(--f-thumb-clip-width, 0));
  cursor: pointer;
  position: absolute;
}

.is-modern .f-thumbs__slide {
  width: var(--f-thumb-clip-width);
  transform: translate3d(calc(var(--shift, 0) * -1px), 0, 0);
  pointer-events: none;
  transition: none;
}

.is-modern.is-resting .f-thumbs__slide {
  transition: transform .33s;
}

.is-modern.is-resting .f-thumbs__slide__button {
  transition: clip-path .33s;
}

.is-using-tab .is-modern .f-thumbs__slide:focus-within {
  filter: drop-shadow(-1px 0px 0px var(--f-thumb-outline-color)) drop-shadow(2px 0px 0px var(--f-thumb-outline-color)) drop-shadow(0px -1px 0px var(--f-thumb-outline-color)) drop-shadow(0px 2px 0px var(--f-thumb-outline-color));
}

.f-thumbs__slide__button {
  appearance: none;
  width: var(--f-thumb-width);
  border-radius: var(--f-thumb-border-radius);
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  height: 100%;
  opacity: var(--f-thumb-opacity);
  background: none;
  border: 0;
  outline: none;
  margin: 0 -100%;
  padding: 0;
  transition: opacity .2s;
  position: relative;
  overflow: hidden;
}

.f-thumbs__slide__button:hover {
  opacity: var(--f-thumb-hover-opacity);
}

.f-thumbs__slide__button:focus:not(:focus-visible) {
  outline: none;
}

.f-thumbs__slide__button:focus-visible {
  opacity: var(--f-thumb-selected-opacity);
  outline: none;
}

.is-modern .f-thumbs__slide__button {
  --clip-path: inset(0 calc(((var(--f-thumb-width, 0)  - var(--f-thumb-clip-width, 0))) * (1 - var(--progress, 0)) * .5) round var(--f-thumb-border-radius, 0));
  clip-path: var(--clip-path);
}

.is-classic .is-nav-selected .f-thumbs__slide__button {
  opacity: var(--f-thumb-selected-opacity);
}

.is-classic .is-nav-selected .f-thumbs__slide__button:after {
  content: "";
  border: var(--f-thumb-outline, 0) solid var(--f-thumb-outline-color, transparent);
  border-radius: var(--f-thumb-border-radius);
  z-index: 10;
  height: auto;
  animation: .2s ease-out f-fadeIn;
  position: absolute;
  inset: 0;
}

.f-thumbs__slide__img {
  width: 100%;
  height: 100%;
  padding: var(--f-thumb-offset);
  box-sizing: border-box;
  pointer-events: none;
  object-fit: cover;
  border-radius: var(--f-thumb-border-radius);
  margin: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.f-thumbs.is-horizontal .f-thumbs__track {
  padding: 8px 0 12px;
}

.f-thumbs.is-horizontal .f-thumbs__slide {
  margin: 0 var(--f-thumb-gap) 0 0;
}

.f-thumbs.is-vertical .f-thumbs__track {
  flex-wrap: wrap;
  padding: 0 8px;
}

.f-thumbs.is-vertical .f-thumbs__slide {
  margin: 0 0 var(--f-thumb-gap) 0;
}

.fancybox__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-border-radius: 2px;
  --f-thumb-outline: 2px;
  --f-thumb-outline-color: #ededed;
  opacity: var(--fancybox-opacity, 1);
  transition: max-height .35s cubic-bezier(.23, 1, .32, 1);
  position: relative;
}

.fancybox__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: .5;
  --f-thumb-hover-opacity: 1;
}

.fancybox__thumbs.is-classic .f-spinner {
  background-image: linear-gradient(#ffffff1a, #ffffff0d);
}

.fancybox__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 16px;
  --f-thumb-clip-width: 46px;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
}

.fancybox__thumbs.is-modern .f-spinner {
  background-image: linear-gradient(#ffffff1a, #ffffff0d);
}

.fancybox__thumbs.is-horizontal {
  padding: 0 var(--f-thumb-gap);
}

.fancybox__thumbs.is-vertical {
  padding: var(--f-thumb-gap) 0;
}

.is-compact .fancybox__thumbs {
  --f-thumb-width: 64px;
  --f-thumb-clip-width: 32px;
  --f-thumb-height: 48px;
  --f-thumb-extra-gap: 10px;
}

.fancybox__thumbs.is-masked {
  max-height: 0 !important;
}

.is-closing .fancybox__thumbs {
  transition: none !important;
}

.fancybox__toolbar {
  --f-progress-color: var(--fancybox-color, #fffffff0);
  --f-button-width: 46px;
  --f-button-height: 46px;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: #18181ba6;
  --f-button-hover-bg: #464649a6;
  --f-button-active-bg: #5a5a5da6;
  --f-button-border-radius: 0;
  --f-button-svg-width: 24px;
  --f-button-svg-height: 24px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-filter: drop-shadow(1px 1px 1px #18181b26);
  --f-button-svg-fill: none;
  --f-button-svg-disabled-opacity: .65;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  text-shadow: var(--fancybox-toolbar-text-shadow, 1px 1px 1px #00000080);
  pointer-events: none;
  z-index: 20;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI Adjusted, Segoe UI, Liberation Sans, sans-serif;
  display: flex;
}

.fancybox__toolbar :focus-visible {
  z-index: 1;
}

.fancybox__toolbar.is-absolute, .is-compact .fancybox__toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.is-idle .fancybox__toolbar {
  pointer-events: none;
  animation: .15s ease-out both f-fadeOut;
}

.fancybox__toolbar__column {
  flex-flow: wrap;
  align-content: flex-start;
  display: flex;
}

.fancybox__toolbar__column.is-left, .fancybox__toolbar__column.is-right {
  flex-grow: 1;
  flex-basis: 0;
}

.fancybox__toolbar__column.is-right {
  flex-wrap: nowrap;
  justify-content: flex-end;
  display: flex;
}

.fancybox__infobar {
  line-height: var(--f-button-height);
  text-align: center;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 5px;
  font-size: 17px;
}

.fancybox__infobar span {
  padding: 0 5px;
}

.fancybox__infobar:not(:first-child):not(:last-child) {
  background: var(--f-button-bg);
}

[data-fancybox-toggle-slideshow] {
  position: relative;
}

[data-fancybox-toggle-slideshow] .f-progress {
  opacity: .3;
  height: 100%;
}

[data-fancybox-toggle-slideshow] svg g:first-child {
  display: flex;
}

[data-fancybox-toggle-slideshow] svg g:last-child, .has-slideshow [data-fancybox-toggle-slideshow] svg g:first-child {
  display: none;
}

.has-slideshow [data-fancybox-toggle-slideshow] svg g:last-child, [data-fancybox-toggle-fullscreen] svg g:first-child {
  display: flex;
}

[data-fancybox-toggle-fullscreen] svg g:last-child, :fullscreen [data-fancybox-toggle-fullscreen] svg g:first-child {
  display: none;
}

:fullscreen [data-fancybox-toggle-fullscreen] svg g:last-child {
  display: flex;
}

.f-progress {
  transform-origin: 0;
  background: var(--f-progress-color, var(--f-carousel-theme-color, #0091ff));
  z-index: 30;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  height: 3px;
  transition-property: transform;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
}

html, body, main, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, input, textarea {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
  overflow: visible;
}

:focus {
  outline: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"]::-webkit-search-results-button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

audio, canvas, video {
  max-width: 100%;
  display: inline;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden] {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 100%;
}

a:focus {
  outline: none;
}

a:active, a:hover {
  outline: 0;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
}

figure, form {
  margin: 0;
}

legend {
  white-space: normal;
  border: 0;
  margin-left: -7px;
  padding: 0;
}

button, input, select, textarea {
  vertical-align: baseline;
  vertical-align: middle;
  margin: 0;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  overflow: visible;
}

button[disabled], html input[disabled] {
  cursor: default;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, button, input, select, textarea {
  color: #000;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  color: #000;
  background: #ccc;
  margin: .2em 0;
  padding: .2em 0;
}

button {
  border: none;
}

h1, h2, h3, h4, p, a, label, button, input, textarea, th, td, li, select, span {
  color: #dbe0ec;
  overflow-wrap: break-word;
}

#dz-app {
  overflow-x: hidden;
}

body {
  overflow-y: overlay;
}

body:before {
  content: "";
  will-change: transform;
  z-index: -1;
  background-color: #000;
  background-image: url("bg_day.5f6a9a2f.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 110%;
  position: fixed;
  top: 0;
  left: 0;
}

body.bg-plain:before {
  background-image: none;
}

main {
  max-width: 960px;
  margin: 100px auto;
  padding: 0 30px;
  overflow-x: visible;
}

@media (width <= 1200px) {
  main {
    max-width: 700px;
  }
}

@media (width <= 900px) {
  main {
    max-width: 100%;
  }
}

@media (width <= 600px) {
  main {
    padding: 0 20px;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #0101014d;
}

::-webkit-scrollbar-thumb:vertical {
  background: #0101014d;
}

::-webkit-scrollbar-thumb:hover {
  background: #01010173;
}

::-webkit-scrollbar-thumb:active {
  background: #01010199;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-track-piece {
  display: none;
}

@media (width >= 601px) {
  .spaced-title {
    margin-top: 25vh;
  }
}

p, label {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 135%;
}

p.fancy, label.fancy {
  font-family: Aozora Mincho, serif;
  font-weight: 200;
}

p + p {
  margin-top: 15px;
}

h1 {
  text-align: center;
  text-shadow: 0 2px 3px #000000b6;
  font-family: Aozora Mincho, serif;
  font-size: 96px;
  font-weight: 200;
  line-height: 96px;
}

@media (width <= 600px) {
  h1 {
    font-size: 70px;
    line-height: 70px;
  }
}

@media (width <= 900px) {
  h1.long {
    display: none;
  }
}

@media (width >= 901px) {
  h1.long-broken-up {
    display: none;
  }
}

@media (width <= 500px) {
  h1.long-broken-up {
    font-size: 57px;
    line-height: 57px;
  }
}

h1.small-print {
  font-size: 60px;
  line-height: 60px;
}

@media (width <= 600px) {
  h1.small-print {
    font-size: 40px;
    line-height: 40px;
  }
}

h2 {
  margin-bottom: 15px;
  font-family: Aozora Mincho, serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
}

h2 .tiny {
  font-size: 20px;
}

.h2-super {
  color: #dbe0ec80;
  letter-spacing: .14rem;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 250;
  line-height: 17px;
}

h3 {
  margin-bottom: 7px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 131%;
}

h4 {
  padding-top: 8px;
  padding-bottom: 3px;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
}

a, .a {
  cursor: pointer;
  background: none;
  padding: 0;
  font-family: Aozora Mincho, serif;
  font-weight: 200;
  text-decoration: underline;
}

a:hover, .a:hover {
  text-decoration: none;
}

a.glow, .a.glow {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

a.glow, a.button, a.hover-glow, .a.glow, .a.button, .a.hover-glow {
  text-decoration: none;
}

a.glow:not(.hover-glow), a.button:not(.hover-glow), a.hover-glow:not(.hover-glow), .a.glow:not(.hover-glow), .a.button:not(.hover-glow), .a.hover-glow:not(.hover-glow) {
  text-shadow: 0 0 #c8caff, 0 0 25px #1e26ff, 0 0 3px #5f9fff, 0 0 10px #4a5cffd9, 0 0 8px #0008ff;
}

a.glow:hover, a.button:hover, a.hover-glow:hover, .a.glow:hover, .a.button:hover, .a.hover-glow:hover {
  text-shadow: 0 0 4px #c8caff, 0 0 10px #1e26ff, 0 0 3px #5f9fff, 0 0 5px #4a5cffd9, 0 0 4px #0008ff;
}

.clickable {
  cursor: pointer;
}

.clickable > * {
  cursor: auto;
}

strong {
  font-weight: 700;
}

button.glow, .button {
  text-shadow: 0 0 #c8caff, 0 0 25px #1e26ff, 0 0 3px #5f9fff, 0 0 10px #4a5cffd9, 0 0 8px #0008ff;
  margin: initial auto;
  text-align: left;
  background-color: #1618224d;
  width: 100%;
  padding: 15px 30px 15px 60px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 135%;
  transition: background-color .2s;
  display: inline-block;
  position: relative;
}

button.glow:before, .button:before {
  content: url("arrow_down.c44e9abb.svg");
  box-sizing: border-box;
  transform-origin: center;
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
  position: absolute;
  left: 12px;
  transform: translateY(-2px)rotate(-90deg)scale(.5);
}

button.glow.back:before, .button.back:before {
  transform: translateY(-2px)rotate(90deg)scale(.5);
}

button.glow.checkbox:before, .button.checkbox:before {
  transform: unset;
  left: 18px;
}

button.glow.checkbox:not(.checked):before, .button.checkbox:not(.checked):before {
  content: url("checkbox_unchecked.770647c4.svg");
  opacity: .5;
}

button.glow.checkbox.checked:before, .button.checkbox.checked:before {
  content: url("checkbox_checked.74edab7c.svg");
}

button.glow.back:not(.checkbox):before, .button.back:not(.checkbox):before {
  transform: translate(-5px, -2px)rotate(90deg)scale(.5);
}

button.glow:hover, .button:hover {
  text-shadow: 0 0 4px #c8caff, 0 0 10px #1e26ff, 0 0 3px #5f9fff, 0 0 5px #4a5cffd9, 0 0 4px #0008ff;
  background-color: #555e8a4d;
}

button.glow:hover:before, .button:hover:before {
  filter: drop-shadow(0 0 4px #c8cafff1) drop-shadow(0 0 10px #1e26ff94) drop-shadow(0 0 3px #5f9fffb3) drop-shadow(0 0 4px #4a5cff80) drop-shadow(0 0 3px #0008ffbd);
}

button.glow:active, .button:active {
  background-color: #6f7cb466;
}

.transparent-panel, .card.transparent-panel {
  background-color: #16182280;
  padding: 15px 20px;
}

li {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 135%;
}

ul.commalist li {
  display: inline-block;
}

ul.commalist li:nth-last-child(2) > span {
  display: none;
}

ul.commalist li:nth-last-child(2):has(span):after {
  content: " en ";
  display: inline-block !important;
}

ul.commalist li:last-child span {
  display: none;
}

ul.columnlist {
  column-count: 2;
}

input, textarea, select {
  background-color: #191b24;
  border: none;
  padding: 4px 8px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 135%;
}

table {
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}

table thead tr th, table thead tr td {
  padding-bottom: 20px;
  font-size: 17px;
}

table tr th {
  text-align: left;
  padding-bottom: 20px;
  padding-right: 20px;
}

table tr th label, table tr th p {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

@media (width <= 900px) {
  table tr th label, table tr th p {
    font-size: 13px;
  }
}

table tr td {
  padding-left: 6px;
  padding-right: 14px;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 135%;
}

table:not(.form-table) tr + tr td:first-child, table:not(.form-table) tr + tr th:first-child {
  padding-top: 10px;
}

@media (width <= 900px) {
  table, table td, table tr, table th {
    text-align: left;
    display: block;
  }

  table tr + tr {
    margin-top: 1.5em;
  }

  table tr td + td {
    margin-left: 0;
    padding-left: 0;
  }

  table td:first-child {
    font-weight: bold;
  }

  table td {
    padding-right: 0;
    padding-left: 0 !important;
  }

  table td + td {
    padding-top: .5em;
  }

  table thead {
    display: none;
  }
}

table input:not([type="checkbox"]), table select, table textarea {
  width: 100%;
  margin-bottom: 4px;
}

table.form-table tbody {
  display: block;
}

table.form-table tr td {
  width: 100%;
  line-height: 11px;
}

@media (width <= 1200px) {
  table.form-table tr th {
    padding-bottom: 6px;
  }
}

.card {
  background-color: #232634;
  height: auto;
  padding: 30px 40px;
  position: relative;
}

.card.invert {
  background-color: #dbe0ec;
}

.card.invert h1, .card.invert h2, .card.invert h3, .card.invert h4, .card.invert p, .card.invert a, .card.invert label, .card.invert button {
  color: #000;
}

@media (width <= 600px) {
  .card {
    padding: 20px 30px;
  }
}

.card .icon {
  position: absolute;
}

@media (width >= 601px) {
  .card .icon {
    top: 0;
    left: -50px;
  }

  .card .icon.right {
    left: initial;
    right: -50px;
  }
}

@media (width <= 600px) {
  .card .icon {
    bottom: 0;
    right: 0;
    transform: scale(.6);
  }
}

.card.glow-off .icon {
  filter: none;
}

.card img.cover {
  width: 100%;
  margin-top: 15px;
}

.article-card {
  background-color: #232634;
  padding: 30px 40px;
}

.article-card:has(h1.title) {
  padding: 10px 30px 40px 40px;
}

.article-card h1.title {
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 30px;
  display: block;
}

.article-card p, .article-card ul {
  margin-bottom: 14px;
}

.article-card ul {
  padding-left: 30px;
  list-style: circle;
}

.article-card h2 {
  margin: 35px 0 20px;
  font-size: 30px;
  line-height: 30px;
}

.layout-spacer + .layout-spacer {
  margin-top: 30px;
}

.vert-layout, .vert-layout-spaced {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.vert-layout.align-start, .vert-layout-spaced.align-start {
  align-items: flex-start;
}

.vert-layout.align-stretch, .vert-layout-spaced.align-stretch {
  align-items: stretch;
}

.vert-layout.align-stretch button, .vert-layout-spaced.align-stretch button {
  width: 100%;
}

.vert-layout > .align-stretch, .vert-layout-spaced > .align-stretch {
  max-width: unset;
  align-self: stretch;
}

@media (width <= 600px) {
  .vert-layout > *, .vert-layout-spaced > * {
    width: 100%;
  }
}

.vert-layout > .align-start {
  align-self: flex-start;
}

.vert-layout > .align-start.inbetween {
  margin-left: 10%;
}

.vert-layout > .align-end {
  align-self: flex-end;
}

.vert-layout > .align-end.inbetween {
  margin-right: 10%;
}

.vert-layout-spaced {
  width: 100%;
}

.vert-layout-spaced > * + * {
  margin-top: 30px;
}

.vert-layout-spaced.small-spacing > * + * {
  margin-top: 15px;
}

@media (width >= 901px) {
  .vert-layout-spaced > .align-start {
    align-self: flex-start;
  }

  .vert-layout-spaced > .align-start.inbetween {
    margin-left: 10%;
  }

  .vert-layout-spaced > .align-end {
    align-self: flex-end;
  }

  .vert-layout-spaced > .align-end.inbetween {
    margin-right: 10%;
  }
}

@media (width >= 1201px) {
  .vert-layout-spaced > .card, .vert-layout-spaced .vert-layout > .card, .vert-layout-spaced .expander > .card {
    min-width: 500px;
    max-width: 600px;
  }
}

@media (width <= 1200px) {
  .vert-layout-spaced > .card, .vert-layout-spaced .vert-layout > .card, .vert-layout-spaced .expander > .card {
    width: 500px;
  }
}

@media (width <= 600px) {
  .vert-layout-spaced > .card, .vert-layout-spaced .vert-layout > .card, .vert-layout-spaced .expander > .card {
    width: 100%;
  }
}

.hor-layout {
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

.hor-layout > * {
  flex-grow: 1;
  display: inline-flex;
}

.grid-layout {
  justify-items: center;
  gap: 30px;
  width: 100%;
  display: grid;
}

.grid-layout .vert-layout-spaced > .card {
  min-width: unset;
  max-width: unset;
  width: unset;
}

@media (width <= 600px) {
  .grid-layout {
    grid-template: auto / minmax(0, 1fr);
    grid-auto-flow: row;
  }
}

.flow-layout {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.freelayout-spacer {
  padding: 10px 20px 0;
}

@media (width >= 601px) {
  .freelayout-spacer {
    padding: 25px 100px 0;
  }
}

.topper {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  display: flex;
}

.topper h2 {
  text-align: center;
  word-wrap: break-word;
  max-width: calc(100vw - 30px);
  padding-top: 10px;
  display: block;
}

.topper .padder {
  width: 250px;
}

[v-cloak] {
  display: none;
}

::selection {
  background: #9194a380 !important;
}

.unbulleted {
  list-style: none;
}

.icon {
  -webkit-user-select: none;
  user-select: none;
  filter: drop-shadow(0 0 40px #ffa800) drop-shadow(0 0 4px #ff7d45) drop-shadow(0 0 15px #ff814a);
  width: 40px;
  height: 40px;
}

.icon.off, .glow-first-item :not(:first-child) .icon {
  filter: drop-shadow(0 2px 3px #0000004d);
}

.text-shadow-small {
  text-shadow: 0 1.5px 1.5px #000000e8;
}

.alert {
  color: green;
  border-size: 5px;
}

.quick-buttons {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.quick-buttons > a {
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 5px;
}

.quick-buttons > a:hover {
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

.quick-buttons .delete-button:after {
  content: url("cross.e7c5bf6d.svg");
}

.quick-buttons .edit-button:after {
  content: url("edit.7bc9fbaf.svg");
}

.quick-buttons .enlarge-button:after {
  content: url("enlarge.fc0aecf3.svg");
}

.quick-buttons .select-button-on:after {
  content: url("checkmark_checked.3435c06d.svg");
}

.quick-buttons .select-button-off:after {
  content: url("checkmark_unchecked.c4737aba.svg");
}

.quick-buttons .eye-button:after {
  content: url("eye.303d2861.svg");
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

.quick-buttons .eye-button:hover:after, .quick-buttons .eyestrikethough-button:after {
  content: url("eye_strikethough.344c32e4.svg");
}

.quick-buttons .eyestrikethough-button:hover:after {
  content: url("eye.303d2861.svg");
}

.quick-buttons .star-button:after {
  content: url("star.00ac47ab.svg");
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

.quick-buttons .star-button:hover:after, .quick-buttons .staroutline-button:after {
  content: url("star_outline.c194e231.svg");
}

.quick-buttons .staroutline-button:hover:after {
  content: url("star.00ac47ab.svg");
}

.icon-btn-inline {
  cursor: pointer;
  padding-right: 3px;
  display: inline;
}

.icon-btn-inline:after {
  vertical-align: bottom;
  max-height: 14px;
  padding-bottom: 4px;
  display: inline-block;
  transform: scale(.9);
}

.icon-btn-inline:hover:after {
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

.icon-plus:after {
  content: url("plus.bf7b522c.svg");
}

.icon-plus.icon-btn-inline:after {
  transform: scale(.8);
}

.icon-select:after {
  content: url("checkbox_unchecked.770647c4.svg");
}

.icon-edit:after {
  content: url("edit.7bc9fbaf.svg");
}

.icon-delete:after {
  content: url("cross.e7c5bf6d.svg");
}

.icon-eye:after {
  content: url("eye.303d2861.svg");
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

.icon-eyestrikethough:after {
  content: url("eye_strikethough.344c32e4.svg");
}

.icon-star:after {
  content: url("star.00ac47ab.svg");
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

.icon-staroutline:after {
  content: url("star_outline.c194e231.svg");
}

nav {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
}

nav .background-blur img {
  z-index: 10;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

nav ul {
  z-index: 11;
  width: 200px;
  position: absolute;
  bottom: 85px;
  left: 30px;
}

nav ul li {
  list-style: none;
  position: relative;
}

nav ul li a {
  text-shadow: none;
  text-transform: capitalize;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 7px 0;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  display: flex;
}

nav ul li a .icon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

nav ul li a:not(.selected) .icon {
  filter: none;
}

nav ul li a:hover .icon {
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

nav .bonfire-container {
  z-index: 11;
  position: absolute;
  bottom: 19px;
  left: 20px;
}

nav .bonfire-container .animation {
  -webkit-user-select: none;
  user-select: none;
  width: 50px;
  height: 50px;
  position: relative;
}

nav .bonfire-container .animation img {
  width: 100%;
  height: 100%;
  position: absolute;
}

nav .bonfire-container .animation .bonfire-fire {
  pointer-events: none;
  transform: scale(2);
}

nav .bonfire-container .animation .bonfire-fire-glow-1, nav .bonfire-container .animation .bonfire-fire-glow-2 {
  pointer-events: none;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: ease;
}

nav .bonfire-container .animation .bonfire-fire-glow-1 {
  opacity: .8;
  filter: blur(7px);
  mix-blend-mode: hard-light;
  transform: scale(2.6);
}

nav .bonfire-container .animation .bonfire-fire-glow-2 {
  opacity: .8;
  filter: blur(4px);
  mix-blend-mode: overlay;
  transform: scale(2.2);
}

nav .bonfire-container .bonfire-cogwheel {
  transition: transform .2s ease-out;
}

nav .bonfire-state {
  z-index: 10;
  width: 200px;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 67px;
}

nav .bonfire-state .bonfire-state-content-wrapper {
  width: auto;
  transition: transform .2s;
  display: block;
  position: relative;
  transform: translateX(0);
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper {
  height: 20px;
  display: block;
  position: relative;
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-short, nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-full, nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-prefix {
  white-space: nowrap;
  text-shadow: 0 0 8px #000;
  text-overflow: ellipsis;
  font-family: Aozora Mincho, serif;
  font-weight: 100;
  display: block;
  position: relative;
  overflow: hidden;
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-short-wrapper, nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-full-wrapper {
  transition: opacity .5s;
  position: absolute;
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-short-wrapper:before, nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-full-wrapper:before {
  content: "";
  z-index: -1;
  filter: blur(27px);
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-short-wrapper {
  opacity: 1;
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-short-wrapper:before {
  width: calc(100% + 40px);
  height: calc(100% + 40px);
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-short-wrapper .bonfire-state-text-short {
  max-width: min(200px, 100vw - 140px);
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-full-wrapper {
  opacity: 0;
  pointer-events: none;
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-full-wrapper:before {
  width: calc(100% + 80px);
  height: calc(100% + 70px);
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-full-wrapper .bonfire-state-text-full {
  max-width: calc(100vw - 140px);
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-prefix {
  color: #ffffffaf;
  pointer-events: none;
  font-size: 11px;
  font-style: italic;
  position: absolute;
  top: -18px;
  left: 0;
}

nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-author {
  opacity: .3;
  margin-top: 2px;
  font-family: Montserrat, sans-serif;
  font-style: italic;
  display: block;
}

nav .bonfire-state .bonfire-state-edit-btn {
  opacity: 0;
  z-index: 1;
  transition: transform .2s, opacity .2s;
  position: absolute;
  top: 18px;
  left: -42px;
  transform: rotate(90deg)translate(47px, -10px)scale(.8);
}

nav .bonfire-state.authenticated:hover .bonfire-state-content-wrapper {
  transform: translateX(29px);
}

nav .bonfire-state.authenticated:hover .bonfire-state-edit-btn {
  opacity: 1;
  transform: rotate(0)translate(47px, -10px)scale(.8);
}

nav .bonfire-state.authenticated:hover .bonfire-state-edit-btn:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

@media (pointer: fine) {
  nav:hover .bonfire-container .animation .bonfire-fire-glow-1, nav:hover .bonfire-container .animation .bonfire-fire-glow-2 {
    filter: blur(1px) drop-shadow(0 0 40px #ffa800) drop-shadow(0 0 4px #ff7d45) drop-shadow(0 0 15px #ff814a);
    opacity: .6;
    transform: scale(2);
  }
}

nav .nav-item-text, nav .nav-bg-legend {
  opacity: 0;
  transition: opacity .5s, transform .5s;
}

nav .nav-item-text {
  transform: translateX(3px);
}

nav .nav-bg-legend {
  transform-origin: 0 100%;
  transform: scale(1.5, 1.2);
}

@media (width >= 901px) {
  nav:hover .nav-item-text, nav:hover .nav-bg-legend {
    opacity: 1;
  }

  nav:hover .nav-item-text {
    transform: translateX(0);
  }

  nav:hover .nav-bg-legend {
    transform: scale(1);
  }

  nav:hover .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-short-wrapper {
    opacity: 0;
  }

  nav:hover .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-full-wrapper {
    opacity: 1;
  }
}

nav .nav-bg-mobile {
  transform-origin: 0 100%;
  transform: scale(.3);
}

@media (width >= 901px) {
  nav .nav-bg-mobile {
    display: none;
  }
}

@media (width <= 900px) {
  nav .nav-bg-icons, nav .nav-bg-legend, nav .legend {
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s, transform .5s;
  }

  nav .bonfire-state {
    opacity: 0;
    transition: opacity .5s;
  }

  nav .bonfire-state .bonfire-state-edit-btn {
    transform: rotate(0)translate(47px, -10px)scale(.8);
  }

  nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-short-wrapper {
    opacity: 0;
  }

  nav .bonfire-state .bonfire-state-content-wrapper .bonfire-state-text-wrapper .bonfire-state-text-full-wrapper {
    opacity: 1;
  }

  nav .bonfire-state.authenticated .bonfire-state-content-wrapper {
    transform: translateX(29px);
  }

  nav .bonfire-state.authenticated .bonfire-state-edit-btn {
    opacity: 1;
  }

  nav.mobile-expanded .nav-bg-icons, nav.mobile-expanded .nav-bg-legend, nav.mobile-expanded .legend {
    opacity: 1;
    pointer-events: all;
  }

  nav.mobile-expanded .nav-item-text {
    opacity: 1;
    transform: translateX(0);
  }

  nav.mobile-expanded .nav-bg-legend {
    transform: scale(1.2, 1.1);
  }

  nav.mobile-expanded .bonfire-cogwheel {
    transform: rotate(90deg);
  }

  nav.mobile-expanded .bonfire-state {
    opacity: 1;
  }
}

@media (width <= 900px) and (pointer: fine) {
  nav.mobile-expanded .bonfire-container:hover .bonfire-cogwheel {
    transform: rotate(75deg);
  }
}

@media (width <= 900px) {
  nav:not(.mobile-expanded) .bonfire-state {
    pointer-events: none;
  }

  nav .bonfire-container:hover {
    cursor: pointer;
  }
}

@media (width <= 900px) and (pointer: fine) {
  nav .bonfire-container:hover .bonfire-cogwheel {
    transform: rotate(15deg);
  }
}

form .error, form .errorlist li {
  color: #ff8944;
  margin-bottom: 4px;
}

form .errorlist + input {
  border: 1px solid #ff8944;
}

form li {
  list-style-type: none;
}

form .helptext {
  margin-bottom: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  line-height: 11px;
  display: block;
}

.expandable.spaced > :first-child {
  margin-top: 15px;
}

.expander .card {
  padding-right: 70px;
  transition: background-color .2s;
}

.expander .card h2 {
  margin-bottom: 0;
}

.expander .card:before {
  content: url("arrow_down.c44e9abb.svg");
  box-sizing: border-box;
  transform-origin: center;
  transition: transform .2s ease-out;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%)scale(.5);
}

.expander:not(.expanded) .card:hover {
  cursor: pointer;
  background-color: #2d3143;
}

.expander:not(.expanded) .card:active {
  background-color: #383c52;
}

.expander .expandable {
  opacity: 0;
  max-height: 0;
  transition: max-height .2s ease-out, opacity .2s ease-out;
  overflow: hidden;
}

.expander.expanded .card:before {
  transform: translate(0, -50%)scale(.5)rotate(180deg);
}

.expander.expanded .expandable {
  opacity: 1;
}

.expander .close-btn {
  pointer-events: none;
  background: #fff0;
  width: 60px;
  height: 100%;
  padding: 0;
  transition: background-color .2s;
  position: absolute;
  top: 0;
  right: 0;
}

.expander.expanded .close-btn {
  pointer-events: all;
}

.expander.expanded .close-btn:hover {
  background-color: #acbbff10;
}

.expander.expanded .close-btn:active {
  background-color: #acbbff23;
}

.image-carousel {
  white-space: nowrap;
  scrollbar-width: none;
  max-width: 100vw;
  height: 250px;
  overflow: scroll hidden;
}

.image-carousel img {
  height: 100%;
  display: inline-block;
}

.image-carousel img + img {
  margin-left: 20px;
}

.image-carousel::-webkit-scrollbar-thumb {
  display: none;
}

.image-carousel:hover::-webkit-scrollbar-thumb {
  display: block;
}

.scroll-down-arrow {
  cursor: pointer;
  animation: 1s infinite alternate float-up-down;
}

@keyframes float-up-down {
  to {
    transform: translateY(20px);
  }
}

.modal-mask {
  z-index: 9998;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: table;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-wrapper {
  vertical-align: middle;
  display: table-cell;
}

.modal-container {
  background-color: #232634;
  border-radius: 2px;
  width: 600px;
  margin: 0 auto;
  padding: 30px 40px;
  font-family: Helvetica, Arial, sans-serif;
  transition: all .3s;
  box-shadow: 0 2px 8px #00000054;
}

@media (width <= 650px) {
  .modal-container {
    width: 90%;
  }
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-body table {
  width: 100%;
}

.modal-footer {
  justify-content: flex-end;
  display: flex;
}

.button-modal {
  text-shadow: 0 0 #c8caff, 0 0 25px #1e26ff, 0 0 3px #5f9fff, 0 0 10px #4a5cffd9, 0 0 8px #0008ff;
  background-color: #1618224d;
  padding: 10px 20px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 135%;
  transition: background-color .2s;
}

.modal-enter, .modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container, .modal-leave-active .modal-container {
  transform: scale(1.1);
}

.owee-banner {
  z-index: 10;
  cursor: pointer;
  width: 100%;
  min-width: 700px;
  height: 350px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 700px) {
  .owee-banner {
    height: 300px;
  }
}

.owee-banner canvas {
  width: 100%;
  height: 100%;
}

.pagination .step-links {
  flex-direction: horizontal;
  height: 50px;
  line-height: 50px;
  display: flex;
}

.pagination .step-links > * + * {
  margin-left: 10px;
}

.pagination .step-links .paginate-btn {
  --scale: 1;
  --left: 0;
  --left-2: 20%;
  width: 50px;
  height: 50px;
  padding: 0;
}

.pagination .step-links .paginate-btn.prev {
  --scale: -1;
}

.pagination .step-links .paginate-btn:hover {
  --left: 10%;
}

.pagination .step-links .paginate-btn:hover.extreme {
  --left: 20%;
}

.pagination .step-links .paginate-btn:hover {
  --left-2: 0;
}

.pagination .step-links .paginate-btn:before {
  content: url("arrow_down.c44e9abb.svg");
  box-sizing: border-box;
  transform-origin: center;
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
  transform: translate(-50%, -50%) scale(var(--scale), 1) translateX(var(--left)) scale(.5) rotate(-90deg);
  transition: transform .2s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.pagination .step-links .paginate-btn.extreme:after {
  content: url("arrow_down.c44e9abb.svg");
  box-sizing: border-box;
  transform-origin: center;
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
  transform: translate(-50%, -50%) scale(var(--scale), 1) translateX(var(--left-2)) scale(.5) rotate(-90deg);
  transition: transform .2s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.pagination .step-links .paginate-btns-placeholder {
  width: 110px;
  height: 50px;
}

#home .hero-wrapper {
  justify-content: center;
  width: 100%;
  height: max(100vh - 100px, 900px);
  display: flex;
}

#home .hero-wrapper .hero {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: min-content;
  margin: auto 0;
  display: flex;
  position: relative;
}

#home .hero-wrapper .hero .title {
  margin-bottom: 30px;
}

@media (width <= 1200px) {
  #home .hero-wrapper .hero .title {
    width: min-content;
  }
}

#home .hero-wrapper .hero .image-carousel {
  height: 200px;
  margin-bottom: 50px;
}

@media (width <= 900px) {
  #home .hero-wrapper .hero .image-carousel {
    height: 150px;
  }

  #home .hero-wrapper .hero .image-carousel img + img {
    margin-left: 10px;
  }
}

#home .hero-wrapper .hero .intro-panel {
  margin-bottom: 30px;
}

#home .hero-wrapper .hero .scroll-down-arrow {
  margin-bottom: 40px;
}

#home .hero-wrapper .hero .background-logo {
  z-index: -1;
  opacity: .4;
  mix-blend-mode: soft-light;
  height: 80vw;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 1200px) {
  #home .hero-wrapper .hero .background-logo {
    height: 100vw;
  }
}

@media (width <= 900px) {
  #home .hero-wrapper .hero .background-logo {
    height: 120vw;
    top: 45%;
  }
}

@media (width <= 600px) {
  #home .hero-wrapper .hero .background-logo {
    height: 130vw;
    top: 32%;
  }
}

@media (pointer: none), (pointer: coarse) {
  #home .hero-wrapper .hero .background-logo {
    opacity: .12;
  }
}

#contact .grid-layout {
  justify-items: normal;
}

@media (width >= 601px) {
  #contact .grid-layout {
    grid-template: auto auto / 3fr 2fr;
  }

  #contact .grid-layout .contact-details-card {
    grid-area: 1 / 1 / 2 / 3;
  }

  #contact .grid-layout .route-card {
    grid-area: 2 / 1 / 3 / 2;
  }

  #contact .grid-layout .small-cards {
    grid-area: 2 / 2 / 3 / 3;
  }
}

#balance-assumption-list {
  list-style-type: square;
  list-style-position: inside;
}

#saldo-form-grid textarea {
  box-sizing: border-box;
  width: 100%;
}

#saldo-form-grid p {
  padding-bottom: 25px;
}

#saldo-form-grid .grid-layout {
  grid-template: auto auto / 1fr 1fr;
  gap: 15px;
}

#saldo-form-grid .grid-layout li {
  width: 100%;
}

#saldo-form-grid .grid-layout input, #saldo-form-grid .grid-layout select {
  box-sizing: border-box;
  width: 100%;
}

#saldo-form-grid .grid-layout p {
  margin-top: 0;
}

#saldo-form-grid .grid-layout label {
  font-size: 13px;
}

.negativebalance {
  color: red;
}

.photoalbum-overview-grid {
  justify-items: normal;
}

@media (width >= 601px) {
  .photoalbum-overview-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width >= 901px) {
  .photoalbum-overview-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (width >= 1201px) {
  .photoalbum-overview-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.photoalbum-overview-grid .albumwrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100%;
  position: relative;
}

.photoalbum-overview-grid .albumwrapper .padded {
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  display: block;
  position: absolute;
}

.photoalbum-overview-grid .albumwrapper h2 {
  word-wrap: break-word;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.photoalbum-overview-grid .albumwrapper a {
  background-color: #0000008c;
  text-decoration: none;
}

.photoalbum-overview-grid .albumwrapper:hover {
  filter: drop-shadow(0 0 4px #c8cafff1) drop-shadow(0 0 10px #1e26ff94) drop-shadow(0 0 3px #5f9fffb3) drop-shadow(0 0 4px #4a5cff80) drop-shadow(0 0 3px #0008ffbd);
}

.photoalbum-editpage-grid {
  justify-items: normal;
}

@media (width >= 601px) {
  .photoalbum-editpage-grid {
    grid-template: auto auto / 2fr 2fr;
  }

  .photoalbum-editpage-grid .edit {
    grid-area: 1 / 1 / span 1 / span 1;
  }

  .photoalbum-editpage-grid .upload {
    grid-area: 1 / 2 / span 1 / span 1;
  }

  .photoalbum-editpage-grid .helptext {
    grid-area: 2 / 1 / span 1 / span 2;
  }

  .photoalbum-editpage-grid .photos {
    grid-area: 3 / 1 / span 1 / span 2;
  }

  .photoalbum-editpage-grid .delete {
    grid-area: 4 / 1 / span 1 / span 2;
  }
}

.photoroll {
  flex-wrap: wrap;
  width: 100%;
  margin: 2px;
  display: flex;
}

.photoroll:after {
  content: "";
  flex-grow: 1000000000;
  order: 999999999;
}

.photoroll > div {
  margin: 2px;
  display: block;
  position: relative;
}

.photoroll > div > i {
  display: block;
}

.photoroll > div > img {
  vertical-align: bottom;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.navbox {
  position: absolute;
  top: 20px;
  left: 20px;
}

@media (width <= 500px) {
  .albumtitle {
    font-size: 57px;
    line-height: 57px;
  }
}

#address-book > div {
  width: 100%;
}

#address-book .card {
  width: auto;
  max-width: initial;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 25px;
}

#address-book .card .contactheader {
  justify-content: space-between;
  display: flex;
}

#address-book .card .contactheader h2 {
  font-size: 24px;
  display: inline-block;
}

@media (width >= 767px) {
  #address-book .card .contactheader h2 {
    white-space: nowrap;
  }
}

#address-book .card .contactheader h2 .tiny {
  font-size: 16px;
}

#address-book .card p {
  margin-top: 8px;
}

#address-book > * + * {
  margin-top: 10px;
}

.zwerver-labels {
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  display: flex;
}

.zwerver-labels.xs {
  justify-content: flex-start;
  margin-top: 0 !important;
}

@media (width >= 767px) {
  .zwerver-labels.xs {
    display: none;
  }
}

.zwerver-labels.lg {
  justify-content: flex-end;
}

@media (width <= 767px) {
  .zwerver-labels.lg {
    display: none;
  }
}

.zwerver-labels div {
  padding: 3px;
}

.zwerver-labels div span {
  border: 1px solid #c9b6b6;
  border-radius: 3px;
  padding: 3px;
  display: block;
}

.zwerver-labels div span.fire {
  color: #fff;
  text-shadow: 0 -2px 4px #fb3, 0 -2px 10px #ff9233, 0 -10px 20px #ff4800, 0 -20px 40px #ff1a1a;
  border: none;
}

.zwerver-labels div span.fire-gold {
  color: #daa520;
  text-shadow: 0 -2px 4px #fff, 0 -2px 10px #ff3, 0 -10px 20px #f90, 0 -20px 40px #c33;
  border: none;
}

.zwerver-labels div span.fire-darkgold {
  color: #0e0b07;
  text-shadow: 0 -2px 4px #d79d5b, 0 -2px 10px #b6803f, 0 -10px 20px #f90, 0 -20px 40px #c33;
  border: none;
}

.zwerver-labels div span.fire-red {
  color: #ff3636;
  text-shadow: 0 -2px 4px #d46f6f, 0 -2px 10px #ff6333, 0 -10px 20px #f90, 0 -20px 40px red;
  border: none;
}

.zwerver-labels div span.internetcom {
  color: green;
  border: none;
  font-family: Bitstream Vera Sans Mono, Monaco, Courier New, Courier, monospace;
}

.zwerver-labels div span.bestuur {
  color: #151f3a;
  background-color: #d3d02b;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: bold;
}

.emergency {
  color: #ff4b4b;
  font-weight: bolder;
}

#meals .meal-description {
  margin-top: 2px;
  font-family: Aozora Mincho, serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
}

#meals .meal-deadline {
  color: #fff;
  text-shadow: 0 0 #c8caff, 0 0 25px #1e26ff, 0 0 3px #5f9fff, 0 0 10px #4a5cffd9, 0 0 8px #0008ff;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 100;
  position: relative;
}

#meals .side-panel input {
  background-color: #16182280;
  width: 100%;
}

#meals .side-panel label {
  font-size: 14px;
}

#meals .side-panel .diet th, #meals .side-panel .diet td {
  display: block;
}

#meals .side-panel .diet th {
  padding-bottom: 8px;
}

#meals .side-panel .auto-meal .title {
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 100;
}

#meals .side-panel .auto-meal .auto-meal-field {
  flex-direction: row;
  height: 40px;
  transition: background-color .2s;
  display: inline-flex;
  position: relative;
}

#meals .side-panel .auto-meal .auto-meal-field label {
  padding: 10px 15px 10px 5px;
  font-size: 15px;
}

#meals .side-panel .auto-meal .auto-meal-field input {
  opacity: 0;
  width: 40px;
  height: 40px;
}

#meals .side-panel .auto-meal .auto-meal-field .checkbox:after {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  content: url("checkbox_unchecked.770647c4.svg");
  position: absolute;
  top: 21px;
  left: 20px;
  transform: translate(-50%, -50%);
}

#meals .side-panel .auto-meal .auto-meal-field input:checked ~ label {
  text-shadow: 0 0 #c8caff, 0 0 25px #1e26ff, 0 0 3px #5f9fff, 0 0 10px #4a5cffd9, 0 0 8px #0008ff;
}

#meals .side-panel .auto-meal .auto-meal-field input:checked ~ .checkbox:after {
  content: url("checkbox_checked.74edab7c.svg");
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}

#meals .side-panel .auto-meal .auto-meal-field:hover {
  background-color: #8d94b84d;
}

#meals .side-panel .auto-meal .auto-meal-field:hover:before {
  filter: drop-shadow(0 0 4px #c8cafff1) drop-shadow(0 0 10px #1e26ff94) drop-shadow(0 0 3px #5f9fffb3) drop-shadow(0 0 4px #4a5cff80) drop-shadow(0 0 3px #0008ffbd);
}

#meals .side-panel .auto-meal .auto-meal-field label:hover, #meals .side-panel .auto-meal .auto-meal-field input:hover {
  cursor: pointer;
}

#meals .side-panel .auto-meal .auto-meal-field:active {
  background-color: #b2b9d766;
}

@media (width >= 1201px) {
  #meals .side-panel {
    width: max(210px, min(300px, 50vw - 520px));
    position: fixed;
    top: 80px;
    right: 50px;
  }

  #meals .side-panel .vert-layout {
    width: 100%;
  }

  #meals .side-panel .card {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
}

#login .forgot-password-link {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 13px;
  display: block;
}

.container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  float: left;
  background-color: #000;
  background-position: center;
  margin: 1px;
  display: block;
  position: relative;
}

.container input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

.container input:checked ~ .checkmark {
  background-image: url("checkbox_checked.74edab7c.svg");
}

.container .checkmark:after {
  border-width: 0 3px 3px 0;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
}

.albumselect {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100%;
  position: relative;
}

.albumselect:hover {
  filter: drop-shadow(0 0 4px #c8cafff1) drop-shadow(0 0 10px #1e26ff94) drop-shadow(0 0 3px #5f9fffb3) drop-shadow(0 0 4px #4a5cff80) drop-shadow(0 0 3px #0008ffbd);
}

.albumselect:has(input:checked) {
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
  outline: 1px solid #add8e6;
}

.checkmark {
  background-image: url("checkbox_unchecked.770647c4.svg");
  background-size: cover;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.checkmark:hover {
  filter: drop-shadow(0 0 20px #1e26ff9d) drop-shadow(0 0 3px #5f9fffcc) drop-shadow(0 0 6px #4a5cffb3) drop-shadow(0 0 4px #0008ff);
}
/*# sourceMappingURL=site.css.map */
